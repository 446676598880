import React from "react";
import Slider from "react-slick";
import Ios from "../assets/images/ios.png";
import Android from "../assets/images/android.png";
import Marketplace1 from '../assets/images/marketplace1.jpg'
import CreateMarketplace from '../assets/images/createmarketplace.jpg'
import EditMarketplace from '../assets/images/editmarketplace.jpg'
import CreateMarketPlacePost from '../assets/images/createmarketplacepost.jpg'
import CreateProductPost from '../assets/images/createproductpost.jpg'
import CreateServicePost from '../assets/images/createservicepost.jpg'
import CreateServicePost2 from '../assets/images/createservicepost2.jpg'
import CreateServicePost3 from '../assets/images/createservicepost3.jpg'
import ServicePost2 from '../assets/images/servicepost2.jpg'
import ViewServicePost from '../assets/images/viewservicepost.jpg'
import Reqserv1 from '../assets/images/reqserv1.jpg'
import Reqserv2 from '../assets/images/reqserv2.jpg'
import Reqserv3 from '../assets/images/reqserv3.jpg'
import Reqserv4 from '../assets/images/reqserv4.jpg'
import BuyerServiceRequest from '../assets/images/buyerservicerequest.jpg'
import NewStore from '../assets/images/newstore.jpg'
import Pendingorder from '../assets/images/pendingorder.jpg'
import SellerManage from '../assets/images/sellermanage.jpg'
import SellerManage2 from '../assets/images/sellermanage2.jpg'
import SellerManage3 from '../assets/images/sellermanage3.jpg'
import SellerManage4 from '../assets/images/sellermanage4.jpg'
import Insight1 from '../assets/images/insight1.jpg'
import Insight2 from '../assets/images/insight2.jpg'
// import { Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";


export default function Docs() {
  const settings = {
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    adaptiveHeight: true,
    // variableWidth: true,
    className: "slides",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <div className="docs-page">
        <div className="docs-wrapper">
          {/* <Sidebar /> */}

          {/* <!--//docs-sidebar--> */}

          <div className="docs-content">
            <div className="container">

              <article className="docs-article" id="marketplace">
                <header className="docs-header">
                  <h1 className="docs-heading">Market Place</h1>
                  <section className="docs-intro">
                    <p>
                      Market Place is an E-commerce feature integrated to Vigoplace which enables users on the platform to sell and buy products as well as offer services.
                      It is richly packed with features and functionalities that provides smooth and stress-free eCommerce experience on Vigoplace.
                    </p>
                  </section>
                  {/* <!--//docs-intro--> */}
                </header>
                <section className="docs-section" id="marketplaceprofile">
                  <h2 className="section-heading">Market Place Profile</h2>
                  <p>
                    A Market Place profile just like other Profile Places on Vigoplace enables a user access marketplace feature such as creating a product or service post as well as carry out other operations which are restricted to only marketplace
                  </p>
                </section>
                {/* <!--//section--> */}

                <section className="docs-section" id="createMP">
                  <h2 className="section-heading">Creating a Market Place</h2>
                  <p>
                    To create a Market Place profile which will allow you access it’s numerous features, Follow the underlisted guidelines:
                  </p>
                  <h5>Steps:</h5>
                  <ol>
                    <li>Go to the profile tab using the bottom navigation on the app.</li>
                    <li>Click on the add icon (+) at the top left corner of the screen reveal the list of available places on the platforms.</li>
                    <li>Select Market Place from the list of options provided.</li>

                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={Marketplace1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>

                    <div className="callout-block callout-block-info">
                      <div className="content">
                        <h4 className="callout-title">
                          <span className="callout-icon-holder me-1">
                            <i className="fas fa-info-circle"></i>
                          </span>
                          Note
                        </h4>
                        <p>You must activate at least one currency wallet to be able to access this feature. An active wallet is needed to perform transactions on the platform</p>
                      </div>
                    </div>

                    <li>Fill out the required information which best describes your business and place of operation.</li>

                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={CreateMarketplace} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on create place to complete the creation of your marketplace.</li>
                  </ol>


                </section>
                {/* <!--//section--> */}

                <section className="docs-section" id="editMp">
                  <h2 className="section-heading">Edit Market Place</h2>
                  <p>
                    To Edit a Market Place, click on the edit Market Place button on your profile.
                  </p>

                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img className="figure-img img-fluid shadow rounded" src={EditMarketplace} alt="" title="CoderPro Home Page" />
                      </div>
                    </div>
                  </div>
                  <p> <b>Edit the information you desire and save</b></p>
                </section>
                {/* <!--//section--> */}


                {/* <!--//section--> */}
                <section className="docs-section" id="createMPP">
                  <h2 className="section-heading">Creating a Market Place Post</h2>
                  <p>
                    You can create two types of posts for a market place. They are product post and service post. These types of posts can only be created within a market place
                  </p>
                  <h3 className="section-heading">Product Post</h3>
                  <p>A product is a tangible item that is put on the market for acquisition, attention, or consumption. This type of post encompasses all physical goods such as electronics, clothing, gadgets etc</p>
                  <h3 className="section-heading">Creating a product Post</h3>
                  <h5>Steps:</h5>
                  <ol>
                    <li>Click on the Add Post button from the bottom navigation tab to reveal extended options.</li>
                    <li>Click on the Product Post option on the list provided.</li>

                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={CreateMarketPlacePost} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Fill out all required fields that best suits your product i.e:
                      <ul>
                        <li>Product name: The title of your product</li>
                        <li>Product description: A descriptive text which adds additional information to buyers</li>
                        <li>Product category: Select a category which best fits your product</li>
                        <li>Product price: You can enter different prices in different currencies for your product</li>
                        <li>Media (Images/Video): Select media files for your product</li>
                      </ul>
                    </li>

                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={CreateProductPost} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the button at the top right to confirm your entry and submit to create a product post.</li>
                  </ol>
                </section>
                {/* <!--//section--> */}

                {/* <!--//section--> */}
                <section className="docs-section" id="servicepost">
                  <h2 className="section-heading">Service Post</h2>
                  <p>
                    A service is an intangible item which arises from the output of one or more individual. This type of post encompasses service such as Catering, Decoration, Disc joker etc.
                  </p>

                  <h2 className="section-heading">Creating a service Post:</h2>
                  <h5>Steps:</h5>
                  <ol>
                    <li>Click on the Add Post button from the bottom navigation tab to reveal extended options</li>
                    <li>Click on the Service Post option on the list provided</li>
                    {/* <div className="simplelightbox-gallery row mb-3">
                      <div className="col-12 col-md-4 mb-3">
                        <img className="figure-img img-fluid shadow rounded" src={CreateServicePost} alt="" title="CoderPro Home Page" />
                      </div>
                    </div> */}
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={CreateServicePost} alt="" title="CoderPro Home Page" />
                          <img className="figure-img img-fluid shadow rounded" src={CreateServicePost2} alt="" title="CoderPro Home Page" />
                          <img className="figure-img img-fluid shadow rounded" src={CreateServicePost3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Fill out all required fields that best suits your service i.e:
                      <ul>
                        <li>Service name: The title of your service</li>
                        <li>Service description: A descriptive text which adds additional information to requesters</li>
                        <li>Service category: Select a category which best fits your service</li>
                        <li>Service price: You can enter different prices in different currencies for your service</li>
                        <li>Media (Images/Video): Select media files for your service</li>

                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img className="figure-img img-fluid shadow rounded" src={ServicePost2} alt="" title="CoderPro Home Page" />
                            </div>
                          </div>
                        </div>

                      </ul>
                    </li>
                    <li>Click on the button at the top right to confirm your entry and submit to create a service post.</li>
                  </ol>

                  <h2 className="section-heading">Viewing Created Post</h2>
                  <p>
                    To view created post, click on profile to view created post. Switch between tabs to get post for each grouping. You can also view uploaded post and services through home feed and explore option
                  </p>


                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img className="figure-img img-fluid shadow rounded" src={ViewServicePost} alt="" title="CoderPro Home Page" />
                      </div>
                    </div>
                  </div>
                </section>
                {/* <!--//section--> */}

                <section className="docs-section" id="purchase">

                  <h2 className="section-heading">Purchase/Request</h2>
                  <p>Uploaded products can be purchased while services can be requested.
                    To make a purchase or service request, locate the post you want from either the feed section, explore section or seller’s profile to initiate a process of order or request
                  </p>

                  <h2 className="section-heading">Purchase Service</h2>
                  <h5>Steps:</h5>
                  <ol>
                    <li>Click on buy on the feed item for product</li>
                    <li>Select preferred transaction currency</li>
                    <li>Select quantity</li>
                    <li>Provide the required information on the form</li>
                    <li>Select preferred checkout method</li>
                    <div className="callout-block callout-block-info">
                      <div className="content">
                        <h4 className="callout-title">
                          <span className="callout-icon-holder me-1">
                            <i className="fas fa-info-circle"></i>
                          </span>
                          Note
                        </h4>
                        <p>Wallet: If wallet is selected, the total amount for the product will be deducted from your wallet.</p>
                        <p>Card: With card payment, you are presented with two payment options depending on the currency you are paying with.</p>
                      </div>
                    </div>
                    <li>Click on make payment to place an order</li>
                  </ol>

                  <h2 className="section-heading">Request Service</h2>
                  <h5>Steps:</h5>
                  <ol>
                    <li>Click on request service on the feed item for service.</li>
                    <li>Select preferred transaction currency.</li>
                    <li>Provide the required information on the form</li>
                    <li>Select preferred checkout method</li>
                    <div className="callout-block callout-block-info">
                      <div className="content">
                        <h4 className="callout-title">
                          <span className="callout-icon-holder me-1">
                            <i className="fas fa-info-circle"></i>
                          </span>
                          Note
                        </h4>
                        <p>Wallet: If wallet is selected, the total amount for the product will be deducted from your wallet.</p>
                        <p>Card: With card payment, you are presented with two payment options depending on the currency you are paying with.</p>
                      </div>
                    </div>
                    <li>Click on make payment to place a request.</li>
                  </ol>

                  {/* <div className="slideContainer">
                  <Slider {...settings}  className="slider mb-3">

                    <div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12">
                          <img className="figure-img img-fluid shadow rounded" src={Reqserv1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <div>
                    <div className="simplelightbox-gallery row">
                        <div className="col-12">
                          <img className="figure-img img-fluid shadow rounded" src={Reqserv2} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <div>
                    <div className="simplelightbox-gallery row">
                        <div className="col-12">
                          <img className="figure-img img-fluid shadow rounded" src={Reqserv3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <div>
                    <div className="simplelightbox-gallery row">
                        <div className="col-12">
                          <img className="figure-img img-fluid shadow rounded" src={Reqserv4} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                  </Slider>
                
                </div> */}

                  <div className="slideContainer2">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12">
                        <img className="figure-img img-fluid shadow rounded" src={Reqserv1} alt="" title="CoderPro Home Page" />
                      </div>
                    </div>
                    <div className="simplelightbox-gallery row">
                      <div className="col-12">
                        <img className="figure-img img-fluid shadow rounded" src={Reqserv2} alt="" title="CoderPro Home Page" />
                      </div>
                    </div>
                    <div className="simplelightbox-gallery row">
                      <div className="col-12">
                        <img className="figure-img img-fluid shadow rounded" src={Reqserv3} alt="" title="CoderPro Home Page" />
                      </div>
                    </div>
                    {/* <div className="simplelightbox-gallery row">
                      <div className="col-12">
                        <img className="figure-img img-fluid shadow rounded" src={Reqserv4} alt="" title="CoderPro Home Page" />
                      </div>
                    </div> */}
                  </div>

                  <h2 className="section-heading mt-5">Manage Order/Service Request</h2>
                  <p>This feature enables both seller and buyer to manage every stage involved in processing an order or a request
                  </p>
                  <h5>Buyer:</h5>
                  <p>Steps To manage your requested services or purchased orders</p>
                  <ol>
                    <li>Go to your profile using the navigation tab.</li>
                    <li>Click on the three dots at the top right corner of the screen to reveal options</li>
                    <li>Click on My Order for order or Requested service to see more information for the selected option respectively.</li>

                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={BuyerServiceRequest} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>

                    <li>On the next screen, you can track your order or service at any point using its status,
                      (PENDING, SHIPPED, COMPLETED, CANCELLED) for order and (PENDING, STARTED, COMPLETED, CANCELLED) for requested service</li>


                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={NewStore} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>



                    <li>Clicking an item on the list will reveal more information for that order or request</li>
                    <li>As a buyer, you are only authorized to cancel a pending order or request or mark an order as received and service as completed</li>

                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={Pendingorder} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                  </ol>

                  <h5>Seller:</h5>
                  <p>Steps To manage your incoming service request or purchased orders</p>
                  <ol>
                    <li>Go to your profile using the navigation tab</li>
                    <li>You can see the number of pending order and pending service request from profile</li>
                    <li>Click on the Manage orders for orders and Manage request for service request</li>

                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={SellerManage} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>

                    <li>On the next screen, you track your order or service at any point using its status,
                      (PENDING, SHIPPED, COMPLETED, CANCELLED) for order and (PENDING, STARTED, COMPLETED, CANCELLED) for requested service
                    </li>

                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={SellerManage2} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Clicking an item on the list will reveal more information for that order or request</li>
                    <li>As a seller, you are authorized to update the status of an order or request at any time</li>

                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={SellerManage3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>

                    <li>Clicking on a list from the completed tab gives you an option to view product or service insight</li>
                    <li>Click on the insight button at the top right corner of the screen to view Insights</li>
                    {/* <div className="simplelightbox-gallery row">
                      <div className="col-12 col-md-4 mb-3">
                        <img className="figure-img img-fluid shadow rounded" src={SellerManage4} alt="" title="CoderPro Home Page" />
                      </div>
                    </div> */}
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={SellerManage4} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>On the Insight Screen, you can view the total quantity sold or requested, total amount made in different currencies, list of customers who have purchased a product or requested an order and also provides a way for you to visit their profile</li>

                    <div className="slideContainer2">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12">
                          <img className="figure-img img-fluid shadow rounded" src={Insight1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12">
                          <img className="figure-img img-fluid shadow rounded" src={Insight1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                  </ol>


                </section>

                <section className="docs-section" id="notification">
                  <h2 className="section-heading">Notification</h2>
                  <p>
                    As a way to run a smooth process, notifications have been made available on Vigoplace to track the entire order or request process.
                    When an order is placed or status changed, the buyer and seller are both notified so they can both know the status of the order or request without stress
                  </p>
                </section>
              </article>
              {/* <!--//docs-article--> */}







              <footer className="footer">
                <div className="footer-bottom text-center py-5">
                  <ul className="social-list list-unstyled pb-4 mb-0">
                    <li className="list-inline-item">
                      <a href="#">

                        <i className="fab fa-facebook-f fa-fw"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">

                        <i className="fab fa-instagram fa-fw"></i>
                      </a>
                    </li>
                  </ul>
                  {/* <!--//social-list--> */}

                  <div className="applink" id="download">
                    <a href="https://apps.apple.com/ng/app/vigoplace/id1622191142">
                      <img className="logo-icon me-2" src={Ios} alt="logo" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.vigoplace.vigoplace">
                      <img className="logo-icon me-2" src={Android} alt="logo" />
                    </a>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
        {/* <!--//docs-wrapper--> */}
      </div>
    </>
  );
}

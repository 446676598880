import React from "react";
import transfer1 from "../assets/images/transfer1.jpg";
import transfer2 from "../assets/images/transfer2.jpg";
import transfer3 from "../assets/images/transfer3.jpg";
import transfer4 from "../assets/images/transfer4.jpg";
import transfer5 from "../assets/images/transfer5.jpg";
import transfer6 from "../assets/images/transfer6.jpg";
import transfer7 from "../assets/images/transfer7.jpg";
import cardfund1 from "../assets/images/cardfund1.jpg";
import cardfund2 from "../assets/images/cardfund2.jpg";
import cardfund3 from "../assets/images/cardfund3.jpg";
import cardfund4 from "../assets/images/cardfund4.jpg";
import payout4 from "../assets/images/payout4.jpg";
import payout5 from "../assets/images/payout5.jpg";
import payout6 from "../assets/images/payout6.jpg";
import payout7 from "../assets/images/payout7.jpg";
import payout8 from "../assets/images/payout8.jpg";
import payout9 from "../assets/images/payout9.jpg";
import payout10 from "../assets/images/payout10.jpg";
import payout11 from "../assets/images/payout11.jpg";
import cardfund5 from "../assets/images/cardfund5.jpg";
import cardfund6 from "../assets/images/cardfund6.jpg";
import cardfund7 from "../assets/images/cardfund7.jpg";
import virtual7 from "../assets/images/virtual7.jpg";
import cardfund8 from "../assets/images/cardfund8.jpg";
import cardfund9 from "../assets/images/cardfund9.jpg";

export default function Wallet() {
  return (
    <>
      <div className="docs-page">
        <div className="docs-wrapper">
          {/* <Sidebar /> */}

          {/* <!--//docs-sidebar--> */}

          <div className="docs-content">
            <div className="container">
              <article className="docs-article" id="walletpin">
                <section className="docs-section" id="walletpin">
                  <h1 className="docs-heading">Wallet</h1>
                  <p>
                    Within Vigoplace, there's also a built-in wallet feature
                    that allows you to securely store your funds, conduct
                    transactions, and perform various financial activities
                    directly within the app.
                  </p>

                  <h5>WALLET FEATURES</h5>

                  <ol>
                    <li>Wallet pin</li>
                    <li>Transfer</li>
                    <li>Fund wallet</li>
                    <li>Payout</li>
                  </ol>
                </section>
              </article>

              <section className="docs-section" id="transfer">
                <br />
                <br />
                <br />
                <br />
                <h1 className="docs-heading">Transfer</h1>
                <p>
                  Vigoplace made it easy to swiftly transfer funds within the
                  app's ecosystem by initiating swift and secure
                  wallet-to-wallet transfers. Seamlessly move your money between
                  accounts, making transactions for purchasing or any other
                  reasons within the app.
                </p>

                <h5>HOW TO MAKE A TRANSFER</h5>

                <p>
                  To make a transfer, the user must have a sufficient amount in
                  his or her Vigoplace wallet before the transfer can be
                  successful.
                </p>

                <ol>
                  <li>
                    Click on your wallet icon at the top right part of your
                    profile screen.
                  </li>

                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={transfer1}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    Put in your wallet pin so that your wallet can be
                    accessible.
                  </li>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={transfer2}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    Click on the desired currency card you want to make the
                    transfer with to navigate to the next page
                  </li>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={transfer3}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={transfer4}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    Click on “Transfer”, this will take you to another page
                    where you will search for the username of the person you
                    want to make the transfer to, the amount you want to
                    transfer and the description of the transfer.
                  </li>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={transfer5}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={transfer6}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    After this is done you click on the “Transfer” bar button at
                    the bottom of the screen.
                  </li>

                  <li>
                    Then your passcode screen will display, input your passcode
                    of your wallet for you transfer to go through.
                  </li>

                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={transfer7}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="callout-block callout-block-info">
                    <div className="content">
                      <h4 className="callout-title">
                        <span className="callout-icon-holder me-1">
                          <i className="fas fa-info-circle"></i>
                        </span>
                        Note
                      </h4>
                      <p>
                        If the amount you are trying to transfer greater than
                        your wallet balances your transfer will not be
                        successful BUT if you meet the requirement then your
                        transfer will be successful.
                      </p>
                    </div>
                  </div>
                </ol>
              </section>
              <section className="docs-section" id="fundwallet">
                <br />
                <br />
                <br />
                <br />
                <h1 className="docs-heading">Fund Wallet</h1>
                <p>
                  Funding of wallet is also possible in a case where you want to
                  purchase an item or content, but you don’t have the amount in
                  your wallet. Funding of wallet can be done in two ways:
                  <ul>
                    <li>Card funding</li>
                    <li>Virtual account funding</li>
                  </ul>
                  Both processes are fast and reliable, but it all depends on
                  your preference.
                </p>

                <h5>CARD FUNDING</h5>

                <p>
                  Card funding is the process of using your virtual bank card to
                  fund your vigoplace wallet. This process involves inputting
                  your details to fund your wallet. NOTE: Each currency card or
                  virtual account works ONLY for that currency funding.
                </p>

                <h5>HOW TO FUND YOUR VIGOPLACE WALLET USING YOUR CARD</h5>

                <ol>
                  <li>Click on your wallet</li>

                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={cardfund1}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    Input your wallet passcode to gain access into your wallet.
                  </li>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={cardfund2}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    Click one the currency wallet card you want to fund to
                    display another page
                  </li>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={cardfund3}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    Click on “Fund wallet” to navigate to another page where
                    you’ll see the methods for funding.
                  </li>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={cardfund4}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={cardfund5}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    Click on card funding to continue, to pop out a drop sheet
                    where you can input the amount you want to fund.
                  </li>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={cardfund6}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={cardfund7}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    After inputting the amount click the button “Fund Wallet” at
                    the bottom of the page continue, then a page will display
                    where you can put in the necessary details asked.
                  </li>

                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={cardfund8}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={cardfund9}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    After inputting the details tap the button at the bottom of
                    the page. Then your wallet transfer is now successful.
                  </li>
                </ol>

                <h5>VIRTUAL ACCOUNT FUNDING</h5>

                <p>
                  In this procedure, you make a direct transfer from your
                  virtual bank out straight to your vigoplace wallet account
                </p>

                <h5>HOW TO FUND YOUR VIGOPLACE WALLET USING VIRTUAL ACCOUNT</h5>

                <ol>
                  <li>Click on your wallet</li>

                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={cardfund1}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    Input your wallet passcode to gain access into your wallet.
                  </li>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={cardfund2}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    Click one the currency wallet card you want to fund to
                    display another page
                  </li>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={cardfund3}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    Click on “Fund wallet” to navigate to another page where
                    you’ll see the methods for funding.
                  </li>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={cardfund4}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={cardfund5}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    Click on Virtual account to continue, this action will pop a
                    drop sheet displaying the account number where you can make
                    the transfer to through your virtual account will be shown
                    to you.
                  </li>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={cardfund6}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={virtual7}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    Copy the account number then go make your transfer from your
                    bank account to the account copied and your vigoplace wallet
                    will be funded immediately
                  </li>
                </ol>
              </section>
              <section className="docs-section" id="payout">
                <br />
                <br />
                <br />
                <br />
                <h1 className="docs-heading">Payout</h1>
                <p>
                  After any transaction and you want to withdraw the money from
                  your vigoplace wallet to your bank account then requesting for
                  a payout is the only way to do so. To request for a payout,
                  you’ll have to create a method for payout, this method is
                  adding the Bank account details where you want your withdrawal
                  to go to.
                </p>

                <h5>STEPS OF REQUESTING A PAYOUT</h5>

                <p>
                  If you want to request for payout, you must have already added
                  the payout method which is the bank details the payout will be
                  sent to. If you haven’t here is how to add a payment method:
                </p>

                <ol>
                  <li>Click on your wallet</li>

                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={cardfund1}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    Input your wallet passcode to gain access into your wallet.
                  </li>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={cardfund2}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    Click on the currency card you want to request your payout
                    from to display another page
                  </li>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={cardfund3}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    Click on long button “Payout”, this will navigate you to
                    another page where you’ll see three taps “PAYOUT METHOD”,
                    “PAYOUT HISTORY" and “REQUEST HISTORY”
                    <ul>
                      <li>
                        Payout method: This is the method of payment when
                        requesting for a payout
                      </li>
                      <li>
                        {" "}
                        Payout history: This is where you see all the history of
                        your previous payouts.
                      </li>
                      <li>
                        Request history: This is tab where all the pending
                        requests are before the request is approved{" "}
                      </li>
                    </ul>
                  </li>

                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={payout4}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    On the “PAYOUT METHOD” tab click on the button “Add payment
                    method” to navigate to another screen that shows your
                    different methods to add.
                  </li>

                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={payout5}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    Click on you preferred method so you then on the screen that
                    displays, fill in the necessary requirements the page
                    provides then click on the bottom button “Validate Account”
                    to save the method you just added.
                  </li>

                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={payout6}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={payout7}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    After successfully adding a method, and you’re back on the
                    page with the three tabs click on the bottom button “Request
                    Payout”.
                  </li>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={payout8}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={payout9}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    That will take you to another page, on this screen you’ll
                    put in the amount you want to withdraw, select a payment
                    method, the description and if you want a normal payout or
                    an expedite payout. A Normal payout takes at least 3-5
                    working days while Expedite payout takes 1-2 working days to
                    complete.
                  </li>

                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={payout10}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={payout11}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <li>
                    Then click on the button “Request Payout” to complete the
                    process.
                  </li>
                </ol>
              </section>
            </div>
          </div>
        </div>
        {/* <!--//docs-wrapper--> */}
      </div>
    </>
  );
}

import React from "react";
import WhiteSvg from "../assets/images/white.svg";
import Ios from "../assets/images/ios.png";
import Android from "../assets/images/android.png";
import Sidebar from "../components/Sidebar";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>
      {/* <header className="header fixed-top">
        <div className="branding docs-branding">
          <div className="navinfo">
            <div className="site-logo">
              <a className="navbar-brand" href="index.html">
                <img className="logo-icon me-2" src={WhiteSvg} alt="logo" />
              </a>
            </div>

            <a href="#download" className="btn d-none d-lg-flex download">
              Download
            </a>
          </div>
        </div>
      </header> */}

      <div className="page-header py-5 text-center position-relative">
        <div className="page-header theme-bg-dark py-5 text-center position-relative">
          <div className="theme-bg-shapes-right"></div>
          <div className="theme-bg-shapes-left"></div>
          <div className="container">
            <h1
              className="page-heading single-col-max mx-auto"
              style={{ color: "white" }}
            >
              Vigoplace Documentation
            </h1>
            <div
              className="page-intro single-col-max mx-auto"
              style={{ color: "white" }}
            >
              Lets walk you through our platform.
            </div>
            <div className="main-search-box pt-3 d-block mx-auto">
              <form className="search-form w-100">
                <input
                  type="text"
                  placeholder="Search the docs..."
                  name="search"
                  className="form-control search-input"
                />
                <button type="submit" className="btn search-btn" value="Search">
                  <i className="fas fa-search"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="docs-overview py-5">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-4 py-3">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title mb-3">
                      <span className="theme-icon-holder homepage-theme-icon-holder card-icon-holder me-2">
                        <i className="fas fa-map-signs"></i>
                      </span>
                      <span className="card-title-text">Market Place</span>
                    </h5>
                    <div className="card-text">
                      Market Place is an E-commerce feature integrated to
                      Vigoplace which enables users on the platform to sell and
                      buy products as well as offer services...
                    </div>
                    <Link className="card-link-mask" to={"/docs/marketplace"} />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 py-3">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title mb-3">
                      <span className="theme-icon-holder homepage-theme-icon-holder card-icon-holder me-2">
                        <i className="fas fa-arrow-down"></i>
                      </span>
                      <span className="card-title-text">Contest Place</span>
                    </h5>
                    <div className="card-text">
                      Contest Place is one of the four places on offer from
                      Vigoplace for a user to choose from and create. Primarily
                      used to create and manage either paid or unpaid voting
                      competitions...
                    </div>

                    <Link
                      className="card-link-mask"
                      to={"/docs/contestplace"}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 py-3">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title mb-3">
                      <span className="theme-icon-holder homepage-theme-icon-holder card-icon-holder me-2">
                        <i className="fas fa-box fa-fw"></i>
                      </span>
                      <span className="card-title-text">Channel Place</span>
                    </h5>
                    <div className="card-text">
                      A channel Place is one of the available places on offer
                      from Vigoplace. Channel place is particularly tailored to
                      content creators who want to monetize their creativity...
                    </div>
                    <Link
                      className="card-link-mask"
                      to={"/docs/channelplace"}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 py-3">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title mb-3">
                      <span className="theme-icon-holder homepage-theme-icon-holder card-icon-holder me-2">
                        <i className="fas fa-cogs fa-fw"></i>
                      </span>
                      <span className="card-title-text">Gift Posts</span>
                    </h5>
                    <div className="card-text">
                      Gift posts are the type of posts you create when you are
                      celebrating something and need people to gift you. It
                      could be any kind of celebration; birthday celebrations,
                      wedding celebrations. e.t.c
                    </div>
                    <Link className="card-link-mask" to={"/docs/giftpost"} />
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 py-3">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title mb-3">
                      <span className="theme-icon-holder homepage-theme-icon-holder card-icon-holder me-2">
                        <i className="fas fa-cogs fa-fw"></i>
                      </span>
                      <span className="card-title-text">News Posts</span>
                    </h5>
                    <div className="card-text">
                      The news post feature on Vigoplace allows users to create
                      and share news-related content.
                    </div>
                    <Link className="card-link-mask" to={"/docs/newspost"} />
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 py-3">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title mb-3">
                      <span className="theme-icon-holder homepage-theme-icon-holder card-icon-holder me-2">
                        <i className="fas fa-cogs fa-fw"></i>
                      </span>
                      <span className="card-title-text">
                        Support and Donate Posts
                      </span>
                    </h5>
                    <div className="card-text">
                      a comprehensive guide on how to create support and donate
                      posts, and how users can support or donate to projects.
                    </div>
                    <Link
                      className="card-link-mask"
                      to={"/docs/supportanddonatepost"}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 py-3">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title mb-3">
                      <span className="theme-icon-holder homepage-theme-icon-holder card-icon-holder me-2">
                        <i className="fas fa-cogs fa-fw"></i>
                      </span>
                      <span className="card-title-text">Fundraising Post</span>
                    </h5>
                    <div className="card-text">
                      The fundraising feature on Vigoplace allows users to
                      create posts to raise funds for projects, events, personal
                      needs, charitable causes, or any other purpose requiring
                      financial support.
                    </div>
                    <Link
                      className="card-link-mask"
                      to={"/docs/fundraisingpost"}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 py-3">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title mb-3">
                      <span className="theme-icon-holder homepage-theme-icon-holder card-icon-holder me-2">
                        <i className="fas fa-cogs fa-fw"></i>
                      </span>
                      <span className="card-title-text">Wallet</span>
                    </h5>
                    <div className="card-text">
                      To Secure Your transactions you’ll have to create a wallet
                      pin to be able to authenticate and access your wallet...
                    </div>
                    <Link className="card-link-mask" to={"/docs/walletpin"} />
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 py-3">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title mb-3">
                      <span className="theme-icon-holder homepage-theme-icon-holder card-icon-holder me-2">
                        <i className="fas fa-cogs fa-fw"></i>
                      </span>
                      <span className="card-title-text">Post Activities</span>
                    </h5>
                    <div className="card-text">
                      Learn how to manage and organize posts according to your
                      preference ...
                    </div>
                    <Link
                      className="card-link-mask"
                      to={"/docs/postactivities"}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 py-3">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title mb-3">
                      <span className="theme-icon-holder homepage-theme-icon-holder card-icon-holder me-2">
                        <i className="fas fa-cogs fa-fw"></i>
                      </span>
                      <span className="card-title-text">Verifications</span>
                    </h5>
                    <div className="card-text">
                      Verification on vigoplace is neccessary To get complete
                      access and easy usage to every feature ...
                    </div>
                    <Link
                      className="card-link-mask"
                      to={"/docs/verifications"}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 py-3">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title mb-3">
                      <span className="theme-icon-holder homepage-theme-icon-holder card-icon-holder me-2">
                        <i className="fas fa-cogs fa-fw"></i>
                      </span>
                      <span className="card-title-text">Pricing</span>
                    </h5>
                    <div className="card-text">
                      Check out the pricing on Vigoplace...
                    </div>
                    <Link className="card-link-mask" to={"/docs/pricing"} />
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 py-3">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title mb-3">
                      <span className="theme-icon-holder homepage-theme-icon-holder card-icon-holder me-2">
                        <i className="fas fa-cogs fa-fw"></i>
                      </span>
                      <span className="card-title-text">Earnings</span>
                    </h5>
                    <div className="card-text">
                      See what you can earn by just being a user of Vigoplace
                    </div>
                    <Link className="card-link-mask" to={"/docs/earnings"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="cta-section text-center py-5  position-relative">
        <div className="theme-bg-shapes-right"></div>
        <div className="theme-bg-shapes-left"></div>
        <div className="container">
          <h3 className="mb-2 text-white mb-3">
            Join the platform of tomorrow now!
          </h3>
          <div className="section-intro text-white mb-3 single-col-max mx-auto">
            sign up at{" "}
            <a className="text-white" href="https://vigoplace.com">
              Vigoplace
            </a>{" "}
            and earn as you socialize.
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="footer-bottom text-center py-5">
          <ul className="social-list list-unstyled pb-4 mb-0">
            <li className="list-inline-item">
              <a href="https://twitter.com/vigoplace/status/1476576302048190474?s=21&t=cG3oEeFdXVvDJax_LbwKSA">
                <i className="fab fa-twitter fa-fw"></i>
              </a>
            </li>

            <li className="list-inline-item">
              <a href="https://facebook.com/vigoplace">
                <i className="fab fa-facebook-f fa-fw"></i>
              </a>
            </li>

            <li className="list-inline-item">
              <a href="https://www.instagram.com/p/CdqGe--sSVu/?igshid=YmMyMTA2M2Y=">
                <i className="fab fa-instagram fa-fw"></i>
              </a>
            </li>
          </ul>

          <div className="applink" id="download">
            <a href="https://apps.apple.com/ng/app/vigoplace/id1622191142">
              <img className="logo-icon me-2" src={Ios} alt="logo" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.vigoplace.vigoplace">
              <img className="logo-icon me-2" src={Android} alt="logo" />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
}

import React from "react";
import postact1 from "../assets/images/postact1.png";
import postact2 from "../assets/images/postact2.png";
import postact3 from "../assets/images/postact3.png";
import postact4 from "../assets/images/postact4.png";
import postact5 from "../assets/images/postact5.png";
import postact6 from "../assets/images/postact6.png";
import postact7 from "../assets/images/postact7.png";
import postact8 from "../assets/images/postact8.png";
import postact9 from "../assets/images/postact9.png";
import postact10 from "../assets/images/postact10.png";
import postact11 from "../assets/images/postact11.png";
import postact12 from "../assets/images/postact12.png";


export default function Postactivities() {


  return (
    <>
      <div className="docs-page">
        <div className="docs-wrapper">
          <div className="docs-content">
            <div className="container">

              <article className="docs-article" id="postactivities">
                <section className="docs-section" id="postactivities">
                  <h1 className="docs-heading">HIDE POST</h1>
                  <p>A user can see a post that isn’t pleasant but doesn’t want to block the user of the post, then the post can be hidden from your timeline</p>
                 
                  <h5>How to hide a post</h5>
                  <ol>
                    <li>Go to the particular post on your timeline and click one the vertical three dot icon at the top right of the post to open a bottom sheet</li>
                    <li>Click on “Hide” to hide the post</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={postact1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={postact2} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>

                  </ol>
                </section>
              </article>

              <section className="docs-section" id="savepost">
                <br/>
                <br/>
                <br/>
                <br/>
              <h1 className="docs-heading">Save Post</h1>
                  <p>A post can be beautiful or interesting to you, you can save that post so as to be able to view the post when ever you want to view it</p>
                  
                  <li>To save a post, go to the particular post on your timeline and click one the vertical three dot icon at the top right of the post to open a bottom sheet</li>
                  <li>Click on “Save” to save the post</li>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={postact3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={postact4} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <h5>to see saved post:</h5>
                    <li>Go to your profile, click one the vertical three dot option icon at the top right corner of the screen</li>
                    <li>II.	A bottom sheet pops up click on the “Saved post” text to take you to a page where all your saved post are located</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={postact5} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={postact6} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>

                    <h5>Un-save a post:</h5>
                    <p>A post can be saved mistakenly or you are tired of the post you saved and want to remove it from your saved contents then a reason to unsave comes in</p>
                    <li>Already in the saved post screen, tap and hold on the desired post you want to unsave, a bottom sheet pops out and gives you the option to unsave the particular post</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={postact7} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={postact8} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
              </section>

              <section className="docs-section" id="archivepost">
                <br/>
                <br/>
                <br/>
                <br/>
              <h1 className="docs-heading">ARCHIVE POST</h1>
                  <p>A user can make a post that he or she doesn’t want anyone to see but doesn’t want to delete the post completely then the user can just archive the post for the period of time</p>
                  
                  <li>To archive a post go to the particular post on your timeline and click one the vertical three dot icon at the top right of the post to open a bottom sheet. Click on “Archive”  to archive the post</li>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={postact9} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <h5>Where to your archived post:</h5>
                    <li>Go to your profile, click one the vertical three dot option icon at the top right corner of the screen</li>
                    <li>A bottom sheet pops up click on the “Archived post” text to take you to a page where all your archived post are</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={postact10} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={postact11} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>

                    <h5>Restore archived post:</h5>
                    <p>AA post can be archived mistakenly or you want to make the post public again you can unarchived the post </p>
                    <li>From the archive post screen, tap and hold on the desired post you want to unarchive, a bottom sheet pops out and gives you the option to unarchive the particular post</li>

                    <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={postact12} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
              </section>
              


            </div>
          </div>
        </div>
        {/* <!--//docs-wrapper--> */}
      </div>
    </>
  );
}

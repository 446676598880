import React from "react";

export default function Pricing() {
  return (
    <>
      <div className="docs-page">
        <div className="docs-wrapper">
          <div className="docs-content">
            <div className="container">
              <article className="docs-article" id="pricing">
                <section className="docs-section" id="pricing">
                  <h1 className="docs-heading">PRICING</h1>
                  <p>
                    For a successful transaction, every transaction on the app
                    incurs a minor percentage-based fee. This approach
                    guarantees that the platform's services remain operational
                    and available for users. The fee is designed to be
                    reasonable and transparent, reflecting a commitment to
                    fairness and sustainability.
                  </p>

                  <h5>How pricing works</h5>
                  <p>
                    Our pricing policy at Vigoplace is created to provide
                    flawless service while sustaining our platform. We apply a
                    small percentage-based fee to each in -app transaction. This
                    fee ensures the smooth functioning of the platform, covering
                    various aspects such as maintenance, development, security,
                    and support. The percentage-based fee is designed to strike
                    a balance between affordability for users and the app's
                    operational needs.
                  </p>
                  <h5>Transparency and Equitability</h5>
                  <p>
                    Transparency is paramount to us. The exact percentage
                    deducted from each transaction is clearly displayed to you
                    before confirming the transaction, creating a transparent
                    and trustworthy environment. Whether you're making a smaller
                    or larger transaction, the fee structure remains consistent
                    and proportionate.
                  </p>
                  <h5>Encourage Excellence</h5>
                  <p>
                    Your participation is crucial. You're actively supporting
                    the survival of Vigoplace by accepting our transaction
                    pricing. We are able to sustain quality, introduce new
                    features, and maintain the level of service excellence you
                    expect.
                  </p>
                </section>
              </article>

              <section className="docs-section" id="fundingwallet">
                <br />
                <br />
                <br />
                <h1>Transactions Pricing</h1>
                <br />
                <h1 className="docs-heading">Funding wallet</h1>
                <br />
                <h5>Funding Naira wallet using a Naira Card</h5>
                <p>Card processing fee (CPF) = 1.5% + 100</p>
                <p>Funding charge (FC) = 10% of Card processing fee</p>
                <p>Total charge = CPF + FC</p>
                <p>Note: 100 fee is waived for transactions under 2,500</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Card processing fee = 15 (1.5% of 1000)</p>
                <p>Funding charge = 1.5 (10% of 15)</p>
                <p>Total fee = 16.5</p>
                <p>Funds to be received = 983.5 (1000 – 16.5)</p>
                <br />

                <h5>Funding US dollar wallet using a USD card</h5>
                <p>Card processing fee (CPF) = 2.9% + a fixed 0.3 cent</p>
                <p>Funding charge (FC) = 10% of Card processing fee (CPF)</p>
                <p>Total charge = CPF + FC</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Card processing fee = 29 (2.9% of 1000)</p>
                <p>Funding charge = 2.9 (10% of 29)</p>
                <p>Total fee = 31.9</p>
                <p>Funds to be received = 961 (1000 – 31.9)</p>
                <br />

                <h5>Funding US dollar wallet using a non-US card</h5>
                <p>Card processing fee (CPF) = 4.9% + a fixed 0.3 cent</p>
                <p>Funding charge (FC) = 10% of Card processing fee (CPF)</p>
                <p>Total charge = CPF + FC</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Card processing fee = 49 (4.9% of 1000)</p>
                <p>Funding charge = 4.9 (10% of 49)</p>
                <p>Total fee = 53.9</p>
                <p>Funds to be received = 946.1(1000 – 53.9)</p>

                <br />
                <h5>Funding via virtual account</h5>
                <p>Virtual account charge (VAC) = 1%</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 100</p>
                <p>Charge amount = 1 (1% of 100)</p>
                <p>Funding charge = 0.1 (10% of 1)</p>
                <p>Total Fee = 1.1</p>
                <p>Funds to be received = 98.9 (100 – 1.1)</p>
              </section>

              <section className="docs-section" id="banktransfer">
                <br />
                <br />
                <br />
                <br />
                <h1 className="docs-heading">Bank Transfer</h1>
                <br />
                <p>For USD with a capped fee of $30</p>
                <h5>Regular or Normal Bank Transfer for USD:</h5>
                <p>Takes 2-3 business days before Bank Transfer is completed</p>
                <p>Bank Transfer charge (BTC) = 2%</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Bank Transfer charge = 20 (2% of 1000)</p>

                <p>Total fee = 20</p>
                <p>Funds to be received = 980 (1000 – 20)</p>
                <br />

                <h5>Expedited Bank Transfer for USD</h5>
                <p>Takes 1-2 business days before Bank Transfer is completed</p>
                <p>Bank Transfer charge (BTC) = 3%</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Bank Transfer charge = 30 (3% of 1000)</p>
                <p>Transfer fee = 30</p>
                <p>Funds to be received = 970 (1000 – 30)</p>
                <br />

                <h5>Regular or Normal Bank Transfer for NGN:</h5>
                <p>Takes 2-3 business days to be completed</p>
                <br />
                <h6>Transaction range - From 0 - 5000</h6>
                <br />
                <p>Card processing fee (CPF) = 10</p>
                <p>
                  Bank Transfer charge (BTC) = 10% of Card processing fee (CPF)
                </p>
                <p>Total charge = CPF + BTC</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Bank Transfer charge = 10 (10% of 1000)</p>
                <p>Transfer fee = 10</p>
                <p>Total fee = 11</p>
                <p>Funds to be received = 989 (1000 – 11)</p>

                <br />

                <h6>Transaction range - From 5,001 - 50,000</h6>
                <br />
                <p>Card processing fee (CPF) = 25</p>
                <p>
                  Bank Transfer charge (BTC) = 10% of Card processing fee (CPF)
                </p>
                <p>Total charge = CPF + BTC</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 10,000</p>
                <p>Card processing fee = 25</p>
                <p>Bank Transfer charge = 2.5 (10% of 25)</p>
                <p>Total fee = 27.5</p>
                <p>Funds to be received = 9,972.5 (10,000 – 27.5)</p>

                <br />

                <h6>Transaction range - Above 50,000</h6>
                <br />
                <p>Card processing fee (CPF) = 50</p>
                <p>
                  Bank Transfer charge (BTC) = 10% of Card processing fee (CPF)
                </p>
                <p>Total charge = CPF + BTC</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 100,000</p>
                <p>Card processing fee = 50</p>
                <p>Bank Transfer charge = 5 (10% of 50)</p>
                <p>Total fee = 55</p>
                <p>Funds to be received = 99,945 (100,000 – 55)</p>

                <br />

                <h5>Expedited Bank Transfer for NGN:</h5>
                <p>Takes 1-2 business days to be completed</p>
                <br />
                <h6>Transaction range - From 0 - 5000</h6>
                <br />
                <p>Card processing fee (CPF) = 10</p>
                <p>
                  Bank Transfer charge (BTC) = 20% of Card processing fee (CPF)
                </p>
                <p>Total charge = CPF + BTC</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Card processing fee = 10</p>
                <p>Bank Transfer charge = 2 (20% of 10)</p>
                <p>Total fee = 12</p>

                <p>Funds to be received = 988 (1000 – 12)</p>

                <br />

                <h6>Transaction range - From 5,001 - 50,000</h6>
                <br />
                <p>Card processing fee (CPF) = 25</p>
                <p>
                  Bank Transfer charge (BTC) = 20% of Card processing fee (CPF)
                </p>
                <p>Total charge = CPF + BTC</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 10,000</p>
                <p>Card processing fee = 25</p>
                <p>Bank Transfer charge = 5 (20% of 25)</p>
                <p>Total fee = 30</p>
                <p>Funds to be received = 9,970 (10,000 – 30)</p>

                <br />

                <h6>Transaction range - Above 50,000</h6>
                <br />
                <p>Card processing fee (CPF) = 50</p>
                <p>
                  Bank Transfer charge (BTC) = 20% of Card processing fee (CPF)
                </p>
                <p>Total charge = CPF + BTC</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 100,000</p>
                <p>Card processing fee = 50</p>
                <p>Bank Transfer charge = 10 (20% of 50)</p>
                <p>Total fee = 60</p>
                <p>Funds to be received = 99,940 (100,000 – 60)</p>

                <br />
              </section>

              <section className="docs-section" id="chargesonearnings">
                <br />
                <br />
                <br />
                <br />
                <h1 className="docs-heading">Charges on Earnings</h1>
                <br />
                <h5>Earnings from Products or Services</h5>
                <p>Product and service charge (P&SC) = 5%</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Charge amount = 50 (5% of 1000)</p>

                <p>Total fee = 50</p>
                <p>Funds to be received = 950 (1000 – 50)</p>
                <br />

                <h5>Earnings from Gift Post</h5>
                <p>Gift charge (GC) = 10%</p>

                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Gift charge amount = 100 (10% of 1000)</p>
                <p>Total fee = 100</p>
                <p>Funds to be received = 900 (1000 – 100)</p>
                <br />

                <h5>Earnings from voting Post</h5>
                <p>Voting charge (VC) = 5%</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Charge amount = 50 (5% of 1000)</p>
                <p>Total fee = 50</p>
                <p>Funds to be received = 950 (1000 – 50)</p>

                <h5>Earnings from subscribing, renting and buying:</h5>
                <p>Channel place charge (CC) = 5%</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Charge amount = 50 (5% of 1000)</p>
                <p>Total fee = 50</p>

                <p>Funds to be received = 950 (1000 – 50)</p>

                <br />

                <h5>Earnings from Fundraising Post</h5>

                <h6>USD Fundraising</h6>
                <br />
                <p>USD Fundraising charge (FRC) = 6%</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Total fee = 60</p>
                <p>Funds to be received = 940</p>

                <br />

                <h6>NGN Fundraising</h6>
                <br />
                <p>NGN Fundraising charge (FRC) = 5%</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1</p>
                <p>Charge amount = 50 (5% of 1000)</p>
                <p>Total fee = 50</p>
                <p>Funds to be received = 950</p>
              </section>
            </div>
          </div>
        </div>
        {/* <!--//docs-wrapper--> */}
      </div>
    </>
  );
}

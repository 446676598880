import React, { useEffect, useState } from 'react'
import { Link, Outlet, useLocation } from "react-router-dom";
import SidebarLinks from './SidebarLinks';



export default function Sidebar({ showSidebar, setShowsidebar }) {
    const { hash } = useLocation();
    const [windowSize, setWindowSize] = useState(getWindowSize());
    // let windowSize = window.innerWidth;
    console.log(windowSize, 'window')
    console.log(showSidebar, 'showSidebars')

    useEffect(() => {
        console.log(hash, 'ppp')

        const urlHash = window.location.hash;
        console.log(urlHash, 'hash me')
        if (urlHash.length) {
            const element = document.getElementById(urlHash.substring(1));
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [hash])

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize)


        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <>
            {/* <div id="docs-sidebar" className="docs-sidebar sidebar-visible"> */}
            {/* <div id="docs-sidebar" className={`docs-sidebar ${showSidebar ? 'sidebar-visible' : "sidebar-hidden"}`}> */}
            <div id="docs-sidebar" className={`docs-sidebar ${windowSize.innerWidth > 1200 || showSidebar ? 'sidebar-visible' : "sidebar-hidden"}`}>
                <div className="top-search-box d-lg-none p-3">
                    <form className="search-form">
                        <input
                            type="text"
                            placeholder="Search the docs..."
                            name="search"
                            className="form-control search-input"
                        />
                        <button type="submit" className="btn search-btn" value="Search">
                            <i className="fas fa-search"></i>
                        </button>
                    </form>
                </div>
                <nav id="docs-nav" className="docs-nav navbar">
                    <ul className="section-items list-unstyled nav flex-column pb-3 pt-2">
                        <li className="nav-item section-title mt-3">
                            <SidebarLinks to={'/docs/marketplace#marketplace'} setShowsidebar={setShowsidebar} text={'Market Place'} >
                                <span className="theme-icon-holder me-2">
                                    <i className="fas fa-box"></i>
                                </span>
                            </SidebarLinks>

                        </li>
                        <li className="nav-item">
                            {/* <a className="nav-link scrollto" href="#createMP">
            Create Market Place
          </a> */}
                            {/* <Link className="nav-link scrollto" to={'/docs/marketplace#createMP'}>Create Market</Link> */}
                            <SidebarLinks to={'/docs/marketplace#createMP'} setShowsidebar={setShowsidebar} text={'Create Market'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/marketplace#editMp'} setShowsidebar={setShowsidebar} text={'Edit  Market Place'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/marketplace#createMPP'} setShowsidebar={setShowsidebar} text={'Posts'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/marketplace#purchase'} setShowsidebar={setShowsidebar} text={'Purchases'} />
                        </li>

                        <li className="nav-item section-title mt-3">
                            <SidebarLinks to={'/docs/contestplace#contestplace'} setShowsidebar={setShowsidebar} text={'Contest Place'} >
                                <span className="theme-icon-holder me-2">
                                    <i className="fas fa-box"></i>
                                </span>
                            </SidebarLinks>
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/contestplace#create'} setShowsidebar={setShowsidebar} text={'Create Contest'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/contestplace#benefit'} setShowsidebar={setShowsidebar} text={'Benefits'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/contestplace#edit'} setShowsidebar={setShowsidebar} text={'Edit'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/contestplace#create-contestants'} setShowsidebar={setShowsidebar} text={'Create Contestants'} />

                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/contestplace#manage-contestants'} setShowsidebar={setShowsidebar} text={'Manage Contestants'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/contestplace#voting'} setShowsidebar={setShowsidebar} text={'Voting'} />
                        </li>


                        {/* channel place */}
                        <li className="nav-item section-title mt-3">
                            <SidebarLinks to={'/docs/channelplace'} setShowsidebar={setShowsidebar} text={'Channel Place'} >
                                <span className="theme-icon-holder me-2">
                                    <i className="fas fa-box"></i>
                                </span>
                            </SidebarLinks>
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/channelplace#createChannel'} setShowsidebar={setShowsidebar} text={'Create Channel Place'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/channelplace#editchannel'} setShowsidebar={setShowsidebar} text={'Edit Channel Place'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/channelplace#paytoview'} setShowsidebar={setShowsidebar} text={'Pay-to-View'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/channelplace#buysetting'} setShowsidebar={setShowsidebar} text={'Buy Setting'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/channelplace#rentsetting'} setShowsidebar={setShowsidebar} text={'Rent Setting'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/channelplace#subsetting'} setShowsidebar={setShowsidebar} text={'Subscription Setting'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/channelplace#views'} setShowsidebar={setShowsidebar} text={'Views'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/channelplace#buypost'} setShowsidebar={setShowsidebar} text={'Buy Posts'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/channelplace#subscribe'} setShowsidebar={setShowsidebar} text={'Subscribe'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/channelplace#basic'} setShowsidebar={setShowsidebar} text={'Basic Posts'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/channelplace#subscribers'} setShowsidebar={setShowsidebar} text={'Subscribers'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/channelplace#sub'} setShowsidebar={setShowsidebar} text={'Subscriptions'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/channelplace#rent'} setShowsidebar={setShowsidebar} text={'Renting'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/channelplace#delete'} setShowsidebar={setShowsidebar} text={'Delete Channel'} />
                        </li>
                        <li className="nav-item section-title mt-3">
                            <SidebarLinks to={'/docs/giftpost#giftpost'} setShowsidebar={setShowsidebar} text={'Gift Post'} >
                                <span className="theme-icon-holder me-2">
                                    <i className="fas fa-box"></i>
                                </span>
                            </SidebarLinks>

                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/giftpost#faq'} setShowsidebar={setShowsidebar} text={'FAQ'} />
                        </li>
                        <li className="nav-item section-title mt-3">
                            <SidebarLinks to={'/docs/newspost#newspost'} setShowsidebar={setShowsidebar} text={'News Post'} >
                                <span className="theme-icon-holder me-2">
                                    <i className="fas fa-box"></i>
                                </span>
                            </SidebarLinks>

                        </li>
                        {/* <li className="nav-item">
                            <SidebarLinks to={'/docs/newspost#faq'} setShowsidebar={setShowsidebar} text={'FAQ'} />
                        </li> */}
                        <li className="nav-item section-title mt-3">
                            <SidebarLinks to={'/docs/supportanddonatepost#supportanddonatepost'} setShowsidebar={setShowsidebar} text={'Support and Donate Post'} >
                                <span className="theme-icon-holder me-2">
                                    <i className="fas fa-box"></i>
                                </span>
                            </SidebarLinks>

                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/supportanddonatepost#suporting'} setShowsidebar={setShowsidebar} text={'Create post'} />
                        </li>
                        <li className="nav-item section-title mt-3">
                            <SidebarLinks to={'/docs/fundraisingpost#fundraisingpost'} setShowsidebar={setShowsidebar} text={'Fundraising Post'} >
                                <span className="theme-icon-holder me-2">
                                    <i className="fas fa-box"></i>
                                </span>
                            </SidebarLinks>

                        </li>
                        {/* <li className="nav-item">
                            <SidebarLinks to={'/docs/newspost#faq'} setShowsidebar={setShowsidebar} text={'FAQ'} />
                        </li> */}

                        <li className="nav-item section-title mt-3">
                            <SidebarLinks to={'/docs/wallet#wallet'} setShowsidebar={setShowsidebar} text={'Wallet'} >
                                <span className="theme-icon-holder me-2">
                                    <i className="fas fa-box"></i>
                                </span>
                            </SidebarLinks>
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/wallet#transfer'} setShowsidebar={setShowsidebar} text={'Transfer'} />
                            <SidebarLinks to={'/docs/wallet#fundwallet'} setShowsidebar={setShowsidebar} text={'Fund Wallet'} />
                            <SidebarLinks to={'/docs/wallet#payout'} setShowsidebar={setShowsidebar} text={'Payout'} />
                        </li>
                        <li className="nav-item section-title mt-3">
                            <SidebarLinks to={'/docs/walletpin#walletpin'} setShowsidebar={setShowsidebar} text={'Wallet Pin'} >
                                <span className="theme-icon-holder me-2">
                                    <i className="fas fa-box"></i>
                                </span>
                            </SidebarLinks>
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/walletpin#resetwalletpin'} setShowsidebar={setShowsidebar} text={'Reset Wallet Pin'} />
                        </li>

                        <li className="nav-item section-title mt-3">
                            <SidebarLinks to={'/docs/postactivities#postactivities'} setShowsidebar={setShowsidebar} text={'Post Activities'} >
                                <span className="theme-icon-holder me-2">
                                    <i className="fas fa-box"></i>
                                </span>
                            </SidebarLinks>
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/postactivities#savepost'} setShowsidebar={setShowsidebar} text={'Save post'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/postactivities#archivepost'} setShowsidebar={setShowsidebar} text={'Archive post'} />
                        </li>


                        <li className="nav-item section-title mt-3">
                            <SidebarLinks to={'/docs/verifications#verifications'} setShowsidebar={setShowsidebar} text={'Verifications'} >
                                <span className="theme-icon-holder me-2">
                                    <i className="fas fa-box"></i>
                                </span>
                            </SidebarLinks>
                        </li>

                        <li className="nav-item section-title mt-3">
                            <SidebarLinks to={'/docs/pricing#pricing'} setShowsidebar={setShowsidebar} text={'Pricing'} >
                                <span className="theme-icon-holder me-2">
                                    <i className="fas fa-box"></i>
                                </span>
                            </SidebarLinks>
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/pricing#fundingwallet'} setShowsidebar={setShowsidebar} text={'Funding wallet'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/pricing#banktransfer'} setShowsidebar={setShowsidebar} text={'Bank transfer'} />
                        </li>
                        <li className="nav-item">
                            <SidebarLinks to={'/docs/pricing#chargesonearnings'} setShowsidebar={setShowsidebar} text={'Charges on earnings'} />
                        </li>
                        <li className="nav-item section-title mt-3">
                            <SidebarLinks to={'/docs/earnings#earnings'} setShowsidebar={setShowsidebar} text={'Earnings'} >
                                <span className="theme-icon-holder me-2">
                                    <i className="fas fa-box"></i>
                                </span>
                            </SidebarLinks>
                        </li>

                    </ul>
                </nav>
                {/* <!--//docs-nav--> */}
            </div>
            <div id="detail">
                <Outlet />
            </div>
        </>
    )
}

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}
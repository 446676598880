import React from "react";
import giftpost1 from "../assets/images/giftpost1.png";
import giftpost2 from "../assets/images/giftpost2.png";
import giftpost3 from "../assets/images/giftpost3.png";
import giftpost4 from "../assets/images/giftpost4.png";
import giftpost5 from "../assets/images/giftpost5.png";
import giftpost6 from "../assets/images/giftpost6.png";
import giftpost7 from "../assets/images/giftpost7.png";
import giftpost8 from "../assets/images/giftpost8.png";
import giftpost9 from "../assets/images/giftpost9.png";
import giftpost10 from "../assets/images/giftpost10.png";


export default function GiftPost() {


  return (
    <>
      <div className="docs-page">
        <div className="docs-wrapper">
          {/* <Sidebar /> */}

          {/* <!--//docs-sidebar--> */}

          <div className="docs-content">
            <div className="container">

              <article className="docs-article" id="giftpost">
                <section className="docs-section" id="giftpost">
                  <h1 className="docs-heading">GIFT POSTS</h1>
                  <p>Gift posts are the type of posts you create when you are celebrating something and need people to gift you. It could be any kind of celebration; birthday celebrations, wedding celebrations. e.t.c.</p>
                  <h5>Who can make a gift post?</h5>
                  <li>Any registered user can make a gift post.</li>
                  <br />
                  <h5>How to create a gift post</h5>
                  <ol>
                    <li>Simply click on the "plus" icon on the bottom navigation bar</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={giftpost1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>You will see the different post types you can make, click on the "Gift post".</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={giftpost2} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>You are immediately presented with the screen where you can create a post (Gift post). Fill in the necessary fields.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={giftpost3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>You have an option to:
                      <ul>
                        <li>set bag as visible (if you want users to be able to see your gift bag)</li>
                        <li>Turn comment off for that particular gift post</li>
                        <li>Hide likes for the gift post</li>
                        <div className="callout-block callout-block-info">
                        <div className="content">
                          <h4 className="callout-title">
                            <span className="callout-icon-holder me-1">
                              <i className="fas fa-info-circle"></i>
                            </span>
                            Note
                          </h4>
                          <p>All these settings can be changed even after you have created the gift post.</p>
                        </div>
                      </div>
                      </ul>
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={giftpost4} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Once you have done these, click on the "Check" button on the top most right of your current screen, this will submit your post (gift post).</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={giftpost5} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                  </ol>
                  <h5 className="text-center">Congratulations, you've just made yourself a gift post.</h5>
                  <br/>
                  <br/>

                  <h5 id="faq">Who can see a created gift post?</h5>
                  <p>Anyone who is logged in on the application can view a gift post, provided the person who made the gift post does not delete the post.</p>

                  <br/>
                  <h5>What is the difference between a gift post and any other post?</h5>
                  <p>A gift post is almost very similar to a "Basic post", what differentiates the two is that whoever makes a gift post can receive gifts in their wallet from other users for the gift post which they created.</p>
                  <br/>
                  <h5>Is it possible to edit an already created gift post</h5>
                  <p>Just like every other post, you can very easily edit a gift post by following these simple steps:</p>
                  <ul>
                    <li>Once you have found the gift post you want to edit, click on the "three dots" on the top right of the post, a bottom sheet with different options will show</li>
                    <li>Simply click on the "Edit", tile and you will be taken to a new screen where you can edit</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={giftpost6} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>You can now edit the content of the post; its description, images etc.</li>
                    <li>Click on the save button and wait for the upload, after a successful upload, you should immediately see you edited post</li>
                  </ul>
                  <br/>

                  <h5>How to "gift" someone from their gift post?</h5>
                  <p>To gift someone, you just have to follow these simple steps:</p>
                  <ul>
                    <li>Every gift post has a "Give gift button" attached to it, just as seen below</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={giftpost7} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>ii.	You will be taken to a new screen containing the different gifts you can gift</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={giftpost8} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>iii.	You can click on the dropdown to select a different currency</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={giftpost9} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Proceed by clicking on any of the gifts, you can increase its quantity or send gift anonymously. Click on the “Gift” button to proceed</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={giftpost10} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>You will be taken to a new screen where you will select the means to gift. there are two options:

                      <ul>
                        <li>Payment through Bank</li>
                        <li>Payment through Wallet</li>

                      </ul>
                    </li>
                  </ul>



                </section>


{/* NB. All these settings can be changed even after you have created the gift post */}

              </article>
              {/* <!--//docs-article--> */}

            </div>
          </div>
        </div>
        {/* <!--//docs-wrapper--> */}
      </div>
    </>
  );
}

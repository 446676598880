import React from "react";
import walletpin1 from "../assets/images/walletpin1.png";
import walletpin2 from "../assets/images/walletpin2.png";
import walletpin3 from "../assets/images/walletpin3.png";
import walletpin4 from "../assets/images/walletpin4.png";
import walletpin5 from "../assets/images/walletpin5.png";
import walletpin6 from "../assets/images/walletpin6.png";
import walletpin7 from "../assets/images/walletpin7.png";
import walletpin8 from "../assets/images/walletpin7.png";


export default function WalletPin() {


  return (
    <>
      <div className="docs-page">
        <div className="docs-wrapper">
          {/* <Sidebar /> */}

          {/* <!--//docs-sidebar--> */}

          <div className="docs-content">
            <div className="container">

              <article className="docs-article" id="walletpin">
                <section className="docs-section" id="walletpin">
                  <h1 className="docs-heading">Wallet Pin</h1>
                  <p>Vigoplace gives you the comfort to save and make transactions without the fear of losing any money. For this reason you’ll have to create a wallet pin to be able to access your wallet</p>
                 
                  <h5>How to create Wallet Pin</h5>
                  <div className="callout-block callout-block-info">
                        <div className="content">
                          <h4 className="callout-title">
                            <span className="callout-icon-holder me-1">
                              <i className="fas fa-info-circle"></i>
                            </span>
                            Note
                          </h4>
                          <p>For you to create a wallet pin, you must have a registered email on vigoplace which is used to open your vigoplace account.</p>
                        </div>
                      </div>
                  <ol>
                    <li>To get started, click on the wallet icon at the top right of your profile page</li>
                    <li>Click on the purple bold text at the centre of the page “TAP HERE TO CREATE A PIN”, a bottom sheet pops out to give you an option to request for an OTP which would be sent to the email you used to register your account</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={walletpin1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={walletpin2} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>iii.	Go to your email and copy the email and paste on the OTP text field, fill in your chosen password and confirm it by retyping same password on the confirm password textfield. NOTE: your wallet pin should only consist of 6 digit.</li>
                    <li>iv.	Tap the purple button at the bottom of the page to save your wallet pin</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={walletpin3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>
                    You can now enter your pin on the pin dialog to be able to access your wallet.
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={walletpin4} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                  </ol>
                </section>
              </article>

              <section className="docs-section" id="resetwalletpin">
                <br/>
                <br/>
                <br/>
                <br/>
              <h1 className="docs-heading">Reset Wallet Pin</h1>
                  <p>Resetting a wallet pin can be done when a user has a wallet already. In a case where the user forgot the pin to the wallet or wants to change it, then you can reset the pass pin right from the wallet page</p>
                  
                  <li>To reset Wallet Pin Click on the “reset pin” text at the bottom of the pin dialog</li>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={walletpin5} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>ii.	You’ll get an OTP message sent to your email automatically. Copy the OTP number and paste on the textfield for OTP and input your new pass pin into the last textfield and click on the “Reset pin” button at the bottom of the page to save your new pass pin</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={walletpin6} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={walletpin7} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>After saving your new pin, type in the new wallet pin you changed to</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={walletpin8} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>

              </section>
              


            </div>
          </div>
        </div>
        {/* <!--//docs-wrapper--> */}
      </div>
    </>
  );
}

import React from "react";
import Slider from "react-slick";
import Ios from "../assets/images/ios.png";
import Android from "../assets/images/android.png";
import Channelplace1 from "../assets/images/createchannel1.jpg";
import Channelplace2 from "../assets/images/createchannel2.jpg";
import Channelplace3 from "../assets/images/createchannel3.jpg";
import Channelplace4 from "../assets/images/createchannel4.jpg";
import Channelplace5 from "../assets/images/createchannel5.jpg";
import Channelplace6 from "../assets/images/createchannel6.jpg";
import Channelplace7 from "../assets/images/createchannel7.jpg";
import EditChannel1 from "../assets/images/editchannel1.jpg";
import EditChannel2 from "../assets/images/editchannel2.jpg";
import EditChannel3 from "../assets/images/editchannel3.jpg";
import EditChannel4 from "../assets/images/editchannel4.jpg";
import EditChannel5 from "../assets/images/editchannel5.jpg";
import EditChannel6 from "../assets/images/editchannel6.jpg";
import Paytoview1 from "../assets/images/paytoview1.jpg";
import Paytoview2 from "../assets/images/paytoview2.jpg";
import Paytoview3 from "../assets/images/paytoview3.jpg";
import Paytoview4 from "../assets/images/paytoview4.jpg";
import Paytoview5 from "../assets/images/paytoview5.jpg";
import Paytoview6 from "../assets/images/paytoview6.jpg";
import Paytoview7 from "../assets/images/paytoview7.jpg";
import Paytoview8 from "../assets/images/paytoview8.jpg";
import Buysetting1 from "../assets/images/buysetting1.jpg";
import Buysetting2 from "../assets/images/buysetting2.jpg";
import Buysetting3 from "../assets/images/buysetting3.jpg";
import Buysetting4 from "../assets/images/buysetting4.jpg";
import Buysetting5 from "../assets/images/buysetting5.jpg";
import Rentsetting1 from "../assets/images/rentsetting1.jpg";
import Rentsetting2 from "../assets/images/rentsetting2.jpg";
import Rentsetting3 from "../assets/images/rentsetting3.jpg";
import Rentsetting4 from "../assets/images/rentsetting4.jpg";
import Rentsetting5 from "../assets/images/rentsetting5.jpg";
import Rentsetting6 from "../assets/images/rentsetting6.jpg";
import Subsetting1 from "../assets/images/subsetting1.jpg";
import Subsetting2 from "../assets/images/subsetting2.jpg";
import Subsetting3 from "../assets/images/subsetting3.jpg";
import Subsetting4 from "../assets/images/subsetting4.jpg";
import Subsetting5 from "../assets/images/subsetting5.jpg";
import Subsetting6 from "../assets/images/subsetting6.jpg";
import Subsetting7 from "../assets/images/subsetting7.jpg";
import Subsetting8 from "../assets/images/subsetting8.jpg";
import Rentapost1 from "../assets/images/rentapost1.jpg";
import Rentapost2 from "../assets/images/rentapost2.jpg";
import Rentapost3 from "../assets/images/rentapost3.jpg";
import Rentapost4 from "../assets/images/rentapost4.jpg";
import Rentapost5 from "../assets/images/rentapost5.jpg";
import Paywithcard1 from "../assets/images/paywithcard1.jpg";
import Paywithcard2 from "../assets/images/paywithcard2.jpg";
import Paywithcard3 from "../assets/images/paywithcard3.jpg";
import Buypost1 from "../assets/images/buypost1.jpg";
import Buypost2 from "../assets/images/buypost2.jpg";
import Buypost3 from "../assets/images/buypost3.jpg";
import Buypost4 from "../assets/images/buypost4.jpg";
import Buypost5 from "../assets/images/buypost5.jpg";
import Buypost6 from "../assets/images/buypost6.jpg";
import Buypost7 from "../assets/images/buypost7.jpg";
import Buypost8 from "../assets/images/buypost8.jpg";
import Buypost9 from "../assets/images/buypost9.jpg";
import Sub1 from "../assets/images/sub1.jpg";
import Sub2 from "../assets/images/sub2.jpg";
import Sub3 from "../assets/images/sub3.jpg";
import Sub4 from "../assets/images/sub4.jpg";
import basic1 from "../assets/images/basic1.jpg";
import basic2 from "../assets/images/basic2.jpg";
import basic3 from "../assets/images/basic3.jpg";
import basic4 from "../assets/images/basic4.jpg";
import basic5 from "../assets/images/basic5.jpg";
import basic6 from "../assets/images/basic6.jpg";
import basic7 from "../assets/images/basic7.jpg";
import basic8 from "../assets/images/basic8.jpg";
import basic9 from "../assets/images/basic9.jpg";
import basic10 from "../assets/images/basic10.jpg";
import basic11 from "../assets/images/basic11.jpg";
import basic12 from "../assets/images/basic12.jpg";
import basic13 from "../assets/images/basic13.jpg";
import subscribers1 from "../assets/images/subscribers1.jpg";
import subscribers2 from "../assets/images/subscribers2.jpg";
import subscribers3 from "../assets/images/subscribers3.jpg";
import subscription from "../assets/images/csub1.jpg";
import csub2 from "../assets/images/csub2.jpg";
import csub3 from "../assets/images/csub3.jpg";
import csub4 from "../assets/images/csub4.jpg";
import csub5 from "../assets/images/csub5.jpg";
import csub6 from "../assets/images/csub6.jpg";
import csub7 from "../assets/images/csub7.jpg";
import csub8 from "../assets/images/csub8.jpg";
import csub9 from "../assets/images/csub9.jpg";
import editcsub1 from "../assets/images/editcsub1.jpg";
import editcsub2 from "../assets/images/editcsub2.jpg";
import editcsub3 from "../assets/images/editcsub3.jpg";
import editcsub4 from "../assets/images/editcsub4.jpg";
import editcsub5 from "../assets/images/editcsub5.jpg";
import editcsub6 from "../assets/images/editcsub6.jpg";
import editcsub7 from "../assets/images/editcsub7.jpg";
import editcsub8 from "../assets/images/editcsub8.jpg";
import deletecsub1 from "../assets/images/deletecsub1.jpg";
import deletecsub2 from "../assets/images/deletecsub2.jpg";
import deletecsub3 from "../assets/images/deletecsub3.jpg";
import deletecsub4 from "../assets/images/deletecsub4.jpg";
import deletecsub5 from "../assets/images/deletecsub5.jpg";
import deletecsub6 from "../assets/images/deletecsub6.jpg";
import rent1 from "../assets/images/rent1.jpg";
import rent2 from "../assets/images/rent2.jpg";
import rent3 from "../assets/images/rent3.jpg";
import rent4 from "../assets/images/rent4.jpg";
import delete1 from "../assets/images/delete1.jpg";
import delete2 from "../assets/images/delete2.jpg";
import delete3 from "../assets/images/delete3.jpg";
import delete4 from "../assets/images/delete4.jpg";
import delete5 from "../assets/images/delete5.jpg";


export default function ChannelPlace() {


  return (
    <>
      <div className="docs-page">
        <div className="docs-wrapper">
          {/* <Sidebar /> */}

          {/* <!--//docs-sidebar--> */}

          <div className="docs-content">
            <div className="container">

              <article className="docs-article" id="contestplace">
                <header className="docs-header">
                  <h1 className="docs-heading">Channel Place</h1>

                  <section className="docs-intro docs-section">
                    <p>
                      A channel Place is one of the available places on offer from Vigoplace. Channel place is particularly tailored to content creators who want to monetize their creativity by creating contents other user can either subscribe, buy or rent before gaining access to the content. The special paid content made undera channel place is called a “Pay-To-View” post.
                    </p>
                    <p>
                      Before creating a channel place the content creator must have an active wallet, this is because for any paid content to be created the creator would have to input an amount that content would cost either to be bought, rented or subscribed to.
                    </p>
                  </section>


                  <section className="docs-section" id="createChannel">
                    <h3>How to create a Channel Place:</h3>
                    <p><b>Follow steps below to Create a Channel on Vigoplace:</b></p>
                    <ol>
                      <li>Click on “profile” on the bottom navigation</li>
                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                           <img className="figure-img img-fluid shadow rounded" src={Channelplace1} alt="" title="CoderPro Home Page" />
                          </div>
                        </div>
                      </div>
                      <li>Click on the + sign at the top left of your screen, a bottom sheet would display</li>
                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                           <img className="figure-img img-fluid shadow rounded" src={Channelplace2} alt="" title="CoderPro Home Page" />
                          </div>
                        </div>
                      </div>
                      <li>Choose the “Channel Place” option to navigate to the creation screen</li>
                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                           <img className="figure-img img-fluid shadow rounded" src={Channelplace3} alt="" title="CoderPro Home Page" />
                          </div>
                        </div>
                      </div>
                      <li>Fill in the text box with the following:
                        <ul>
                          <li>Upload an image for the profile display photo for the channel</li>
                          <li>Name of the channel</li>
                          <li>Select a category that would describe your channel place</li>
                          <li>Description of the channel place</li>
                        </ul>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                             <img className="figure-img img-fluid shadow rounded" src={Channelplace4} alt="" title="CoderPro Home Page" />
                            </div>
                          </div>
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                             <img className="figure-img img-fluid shadow rounded" src={Channelplace5} alt="" title="CoderPro Home Page" />
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>Click on the bottom rectangular box “Create place”</li>
                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                           <img className="figure-img img-fluid shadow rounded" src={Channelplace6} alt="" title="CoderPro Home Page" />
                          </div>
                        </div>
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                           <img className="figure-img img-fluid shadow rounded" src={Channelplace7} alt="" title="CoderPro Home Page" />
                          </div>
                        </div>
                      </div>
                      <div className="callout-block callout-block-info">
                        <div className="content">
                          <h4 className="callout-title">
                            <span className="callout-icon-holder me-1">
                              <i className="fas fa-info-circle"></i>
                            </span>
                            Note
                          </h4>
                          <p>if the place was created successful, a green snackbar displays at the bottom of the screen. The screen pops</p>
                          <p>if the creation wasn’t successful, a red message shows to give the creator information why it wasn’t successful</p>
                        </div>
                      </div>
                    </ol>
                  </section>
                  {/* <!--//docs-intro--> */}
                </header>

                <section className="docs-section" id="editchannel">
                  <h3>EDIT A CHANNEL PLACE</h3>
                  <p>Channel places can be edited. A creator can change or edit things like:</p>
                  <ol>
                    <li>The profile display photo of the channel place.</li>
                    <li>The name of the channel place</li>
                    <li>The location</li>
                    <li>category</li>
                    <li>The description</li>
                  </ol>
                  <p>Follow steps below to Edit a Channel on Vigoplace:</p>

                  <ol>
                    <li>Click on “profile” on the bottom navigation.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={EditChannel1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the “Edit Place” button to go to the editing screen</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={EditChannel2} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={EditChannel3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Edit the information you want, its also possible to change all the information</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={EditChannel4} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>After editing your desired information, click on the top right check icon to save your changes</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={EditChannel5} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={EditChannel6} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                  </ol>

                </section>

                <section className="docs-section" id="paytoview">
                  <h3>PAY-TO-VIEW POST</h3>
                  <p>Pay-To-View are type of posts (which can either be a video or an image) that are made in Vigoplace that enable a user upload any content that would be paid for before it can be viewed. A Pay-To-View post can only be uploaded on a Channel place, for posts like videos, Vigoplace made it possible for a user to upload trailers so that the content can be viewed for a short period of time before the other user can purchase or subscribe to it.  But in a case where the post is an image a user that hasn’t purchased or subscribed would not be able to see the image</p>
                  <h5>HOW TO CREATE A PAY-TO-VIEW POST</h5>
                  <p>Creating a Pay-to-View post on channel place is one of the main purposes of creating a channel place, to create a Pay-to-View post a user must have a channel place account because it is the only place that supports paid posts on Vigoplace. For a post to be a Pay-To-View post, the post would have to be linked to a particular subscription or a one-time payment (buy or sell).</p>

                  <div className="callout-block callout-block-info">
                    <div className="content">
                      <h4 className="callout-title">
                        <span className="callout-icon-holder me-1">
                          <i className="fas fa-info-circle"></i>
                        </span>
                        OPTIONAL TRAILER CLIP
                      </h4>
                      <p>This is a feature in channel place that allow the user upload a preview or advertisement of the intended full video. The purpose why a trailer feature was made is because when a user that hasn’t purchased a content, they can watch a trailer of that content so that they decide if they like the content and want to purchase it. It helps make the other user make decisions before purchasing the content</p>
                      <p>However, a user can also make a video post without adding a trailer to it. For a case like this, Vigoplace made a default 30 sec play time for videos uploaded without trailer. This default play time would display the main video uploaded for only 30 secs before pooping out a wall so a user can purchase the video (if the user hasn’t subscribed or purchased the content).</p>
                    </div>
                  </div>
                  <p><b>Follow steps below to create a Pay-to-View post:</b></p>
                  <div className="callout-block callout-block-info">
                    <div className="content">
                      <h4 className="callout-title">
                        <span className="callout-icon-holder me-1">
                          <i className="fas fa-info-circle"></i>
                        </span>
                        OPTIONAL TRAILER CLIP
                      </h4>
                      <p>Before creating a Pay-to-View post the user must have a channel place account already, if the user doesn’t have a channel place account, go to the document for channel place to see steps on how to create a place</p>
                    </div>
                  </div>


                  <ol>
                    <li>Choose the particular channel place account you want to use to make your post.</li>
                    <li>Click the + icon at the bottom navigator bar to navigate to the screen where you can make a post</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Paytoview1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Paytoview2} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on “Pay-to-View Post” to navigate into another screen where you can make a post</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Paytoview3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Paytoview4} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>

                    <li>Choose a kind of post you want to make (either a video or a photo) and write out a description for your content. If the content is a video and you want to add a trailer to it, click on the “Upload optional trailer clip” text to create a trailer for your video. After creating your trailer click on the top right check sign to save your trailer</li>

                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Paytoview5} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click the top right next sign of the screen to continue the process of uploading your post</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Paytoview6} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Paytoview7} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>In this screen, the user would choose what kind of purchase setting the post would get. Either a buy setting, rent setting, or to subscription setting before another user can get access to the post</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Paytoview8} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                  </ol>

                </section>

                <section className="docs-section" id="buysetting">
                  <h3>Buy Setting</h3>
                  <p>A user can choose to make a post that can only be bought at a particular price before it can be viewed. Buying a content on channel place can only be possible if the user makes the posts buyable, and that can be done from this buy setting when making a post.</p>
                  <div className="callout-block callout-block-info">
                    <div className="content">
                      <h4 className="callout-title">
                        <span className="callout-icon-holder me-1">
                          <i className="fas fa-info-circle"></i>
                        </span>
                        Note
                      </h4>
                      <li>When a post setting is for buy only and that post has been bought, that particular post can’t be deleted again.</li>
                      <li>Before a user can make a buyable post, the user must have an active wallet of the preferred currency you want the post to carry.</li>
                    </div>
                  </div>
                  <h5>Steps to adding a buy setting to a post:</h5>
                  <ol>
                    <li>Click on the “Buy Settings” to navigate to the next page.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Buysetting1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the purple “Add another currency” text to select a currency you want for your post amount. To be able to select currencies you’ll would already have the wallet for that currency</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Buysetting2} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Buysetting3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Put in the amount you want your content to be bought for</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Buysetting4} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the top right check icon to save your buy setting amount.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Buysetting5} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <div className="callout-block callout-block-info">
                      <div className="content">
                        <h4 className="callout-title">
                          <span className="callout-icon-holder me-1">
                            <i className="fas fa-info-circle"></i>
                          </span>
                          Note
                        </h4>
                        <p>The screen pops back with a red message at the bottom of the screen if the buy settings was not successful. Read the message it shows to know why the setting wasn’t successful</p>
                        <p>Hence, if the creation wasn’t successful, a red message shows to give the creator information why it wasn’t successfulHence, if the setting was successful, a green snackbar displays at the bottom of the screen</p>
                      </div>
                    </div>


                  </ol>

                </section>

                <section className="docs-section" id="rentsetting">
                  <h3>Rent Setting</h3>
                  <p>A user can also choose to create a post that can only be rented for a particular period of time and a fixed price before it can be viewed. Renting a content on channel place can only be possible if the post settings is just for a rental purpose. Steps of creating a rental post</p>
                  <h5>Steps to set up Rent:</h5>
                  <ol>
                    <li>Click on the “Rent Settings” to navigate to the next page</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Rentsetting1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the purple “Add another currency” text to select a currency you want for your post amount. To be able to select currencies, wallet for those currencies should be available</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Rentsetting2} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Rentsetting3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Set in a price you want to rent your post</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Rentsetting4} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Set in a rent duration you want the post to be</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Rentsetting5} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the top right check icon to save your rent setting amount</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Rentsetting6} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <div className="callout-block callout-block-info">
                      <div className="content">
                        <h4 className="callout-title">
                          <span className="callout-icon-holder me-1">
                            <i className="fas fa-info-circle"></i>
                          </span>
                          Note
                        </h4>
                        <p>The screen pops back with a red message at the bottom of the screen if the rent settings was not successful. Read the message it shows to know why the setting wasn’t successful</p>
                        <p>Hence, if the setting was successful, a green snackbar displays at the bottom of the screen</p>
                      </div>
                    </div>

                  </ol>

                </section>

                <section className="docs-section" id="subsetting">
                  <h3>SUBSCRIPTION  Setting</h3>
                  <p>For these settings the user must have a subscription created already to be able to assign a subscription plan to the post, the amount set on the subscription can only be changed by editing your subscription, for help on how to change the amount read through the Edit subscription on Subscription documentation. These settings allow the other user to subscribe to your place and allow them to watch any content on your place</p>
                  <h5>Steps to set up Sbscription:</h5>
                  <div className="callout-block callout-block-info">
                    <div className="content">
                      <h4 className="callout-title">
                        <span className="callout-icon-holder me-1">
                          <i className="fas fa-info-circle"></i>
                        </span>
                        Note
                      </h4>
                      <p>Before a user can make a post that can be available to subscribe, the user must have an active wallet and a created subscription of the preferred currency you want the post to carry.</p>
                    </div>
                  </div>
                  <ol>
                    <li>Click on the “Subscription Settings” to navigate to the next page</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Subsetting1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the purple “Add another subscription” text to set the subscription plan for the post</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Subsetting2} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Subsetting3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Select a subscription you want to assign to your post.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Subsetting4} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the top right check icon to save your subscription setting amount</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Subsetting5} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <div className="callout-block callout-block-info">
                      <div className="content">
                        <h4 className="callout-title">
                          <span className="callout-icon-holder me-1">
                            <i className="fas fa-info-circle"></i>
                          </span>
                          Note
                        </h4>
                        <p>The screen pops back with a red message at the bottom of the screen if the subscription settings was not successful. Read the message it shows to know why the setting wasn’t successful.</p>
                        <p>Hence, if the setting was successful, a green snackbar displays at the bottom of the screen.</p>
                      </div>
                    </div>
                    <li>There are other options the user can use to personalise their posts such as
                      <ul>
                        <li>Turning off or on comments on the post</li>
                        <li>Hiding of likes</li>
                        <li>Turning on and off of tagging on the post</li>
                      </ul>
                    </li>
                    <li>The next step for uploading a Pay-To-View post, the user must have to check the box agreeing to the Terms and Condition of making the post. The user can click on the purple “Terms and Condition” text to read and understand about the terms and condition.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Subsetting6} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Subsetting7} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Finally, to finish the upload click on the rectangular “Finish and Upload” button at the bottom of the screen.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Subsetting8} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                  </ol>


                </section>

                <section className="docs-section" id="views">
                  <h3>Views</h3>
                  <h5>WHERE TO VIEW YOUR OWN PAY-TO-VIEW POST</h5>
                  <p>After creating and uploading a pay-to-view post and you want to see post you’ve made, you can do that by going to the channel profile used to create that particular post. On the profile there are three (3) tabs a Pay-To-View, Posts, and subscribers. Clicking on the Pay-To-View tab would display all the paid content you’ve made on that channel. You can also view your post by going through your feed on Home just like any other post.</p>
                  <div className="callout-block callout-block-info">
                    <div className="content">
                      <h4 className="callout-title">
                        <span className="callout-icon-holder me-1">
                          <i className="fas fa-info-circle"></i>
                        </span>
                        Note
                      </h4>
                      <p>You are the only one that can view your post free from the Home feed area but a visitor who hasn’t purchased your content can’t view them without purchasing the content</p>
                    </div>
                  </div>
                  <h5>VIEWING A PAY-TO-VIEW POST AS A VISITOR</h5>
                  <p>Viewing a Pay-To-View as a visitor can happen in two (2) ways and each way if the the visitor doesn’t have a subscription or have purchase the content, the content would not be open only in a case where it is a video that has a trailer. The first way to view a Pay-to-View post is by coming across it on the Home Feed while scrolling through feed and viewing it from the person’s profile directly. But as said earlier if a visitor doesn’t have a subscription or hasn’t purchased the content the content can’t be viewed. For this reason, this part of the document shows you steps as a visitor to subscribe or purchase a content so that toy can view the content</p>
                  <h5>HOW TO PURCHASE A CONTENT AS A VISITOR</h5>
                  <p>To view a post on channel place, you’ll have to purchase the content with the amount the content comes with. Posts on channel place can be made for purchase in different ways by renting, buying or totally subscribing to the channel place.</p>



                </section>

                <section className="docs-section" id="rentapost">
                  <h3>RENT A POST</h3>
                  <p>Renting a post gives the rentee access to the content for the particular period of time assigned to the post. Renting a post can only be possible when the post comes with an option to rent it. But to do this some factors are required such as:</p>
                  <ul>
                    <li>The post must have a rent option before it can be rented</li>
                    <li>The visitor must have an active wallet with the same currency at which the post comes with</li>
                    <li>The active wallet must have the amount set for the post to be rented</li>
                  </ul>
                  <h5>Steps To Rent a Post:</h5>
                  <ol>
                    <li>Go to the particular post you want to rent, click on the rectangular box with text “Pay to gain full access to this content” a bottom sheet would display</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Rentapost1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the box and select the “Rent” option from the bottom sheet that pops out</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Rentapost2} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the option the rent comes with and make your payment</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Rentapost3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <h5>STEPS TO PAY WITH WALLET:</h5>
                    <p>You can make your rental payment using your wallet, but before choosing to pay with your wallet some factors are required such as:</p>
                    <ul>
                      <li>You must have an active wallet in the particular currency in which the other user requires to rent their content.</li>
                      <li>Your wallet must have the amount in which you want to rent the content with</li>
                    </ul>
                    <br />
                    <h5>If all the above requirements are correct, below are steps to rent a content using your wallet:</h5>
                    <ul>
                      <li>Choose the “Wallet” and click on the button at the bottom of the screen to navigate to the payment screen</li>
                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                           <img className="figure-img img-fluid shadow rounded" src={Rentapost4} alt="" title="CoderPro Home Page" />
                          </div>
                        </div>
                      </div>
                      <li>At the bottom of the payment screen, there are two options “No, cancel” and “Yes”. Click on “Yes” to rent the post using your wallet, you can click on “No, cancel” to cancel the renting and go back.</li>
                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                           <img className="figure-img img-fluid shadow rounded" src={Rentapost5} alt="" title="CoderPro Home Page" />
                          </div>
                        </div>
                      </div>
                      <div className="callout-block callout-block-info">
                        <div className="content">
                          <h4 className="callout-title">
                            <span className="callout-icon-holder me-1">
                              <i className="fas fa-info-circle"></i>
                            </span>
                            Note
                          </h4>
                          <p>The screen pops back with a red message at the bottom of the screen if your payment was not successfull. Read the message it shows to know why the payment wasn’t successful</p>
                          <p>Hence, if your payment was successful, a green snackbar displays at the bottom of the screen. And you can view the rented post</p>
                        </div>
                      </div>
                      <h5>STEPS TO PAY WITH CARD:</h5>
                      <p>Vigoplace made it also possible for you to make your payment to subscribe using an eligible Bank Card, but before choosing to pay with a Bank Card some factors are required such as:
                      </p>
                      <ul>
                        <li>The Bank Card in use should be an active one</li>
                        <li>The Bank account should have the particular currency in which the other user requires to Buy the content.</li>
                        <li>Your Bank account balance should have the amount in which you want to Buy the content with.</li>
                      </ul>
                    </ul>
                    <h5>If all the above requirements are met, below are steps to subscribe using your Bank card:</h5>

                    <ul>
                      <li>Choose the “Bank Card” and click on the button at the bottom of the screen to navigate to the payment screen.</li>
                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                           <img className="figure-img img-fluid shadow rounded" src={Paywithcard1} alt="" title="CoderPro Home Page" />
                          </div>
                        </div>
                      </div>
                      <li>At the bottom of the payment screen, there are two options “No, cancel” and “Yes”. Click on “Yes” to continue your rent payment with your cardt, you can click on “No, cancel” to cancel and go back</li>
                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                           <img className="figure-img img-fluid shadow rounded" src={Paywithcard2} alt="" title="CoderPro Home Page" />
                          </div>
                        </div>
                      </div>
                      <li>If your option is yes, a card pops out with the requirement to fill in your bank details, click on the pay to complete your payment</li>
                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                           <img className="figure-img img-fluid shadow rounded" src={Paywithcard3} alt="" title="CoderPro Home Page" />
                          </div>
                        </div>
                      </div>
                      <div className="callout-block callout-block-info">
                        <div className="content">
                          <h4 className="callout-title">
                            <span className="callout-icon-holder me-1">
                              <i className="fas fa-info-circle"></i>
                            </span>
                            Note
                          </h4>
                          <p>The screen pops back with a red message at the bottom of the screen if your payment was not successfull. Read the message it shows to know why the payment wasn’t successful</p>
                          <p>Hence, if your payment was successful, a green snackbar displays at the bottom of the screen. And you can view the post you bought</p>
                        </div>
                      </div>
                    </ul>



                  </ol>


                </section>

                <section className="docs-section" id="buypost">
                  <h3>BUY A POST</h3>
                  <p>To get total access to another user’s particular content, it’s also possible to buy any content from a user’s Channel place and save it. Before you can be able to buy a content, the content must have a buy option. </p>
                  <h5>To buy a content, follow the below instructions:</h5>
                  <ol>
                    <li>Go to the post you want to buy</li>
                    <li>Click on the rectangular bar at the middle of the post card, a bottom sheet pops out.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Buypost1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the “Buy” text to go to the next page</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Buypost2} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the card to navigate to the method of payment.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Buypost3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Choose a method to pay with either by Bank Card or Wallet
                      <br />
                      <br />

                      <h5>Steps to Pay with Wallet:</h5>
                      <p>You can make your payment to buy a content using your wallet, but before choosing to pay with your wallet some factors are required such as:</p>
                      <ul>
                        <li>You must have an active wallet in the particular currency in which the other user requires to Buy their content</li>
                        <li>Your wallet must have the amount in which you want to Buy the content with</li>
                      </ul>
                      <br />
                      <h5>If all the above requirements are correct, below are steps to Buy a content using your wallet:</h5>
                      <ol>
                        <li>Choose the “Wallet” and click on the button at the bottom of the screen to navigate to the payment screen.</li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                             <img className="figure-img img-fluid shadow rounded" src={Buypost4} alt="" title="CoderPro Home Page" />
                            </div>
                          </div>
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                             <img className="figure-img img-fluid shadow rounded" src={Buypost5} alt="" title="CoderPro Home Page" />
                            </div>
                          </div>
                        </div>
                        <li>At the bottom of the payment screen, there are two options “No, cancel” and “Yes”. Click on “Yes” to subscribe to the post, you can click on “No, cancel” to cancel the subscription and go back</li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                             <img className="figure-img img-fluid shadow rounded" src={Buypost6} alt="" title="CoderPro Home Page" />
                            </div>
                          </div>
                        </div>
                        <div className="callout-block callout-block-info">
                          <div className="content">
                            <h4 className="callout-title">
                              <span className="callout-icon-holder me-1">
                                <i className="fas fa-info-circle"></i>
                              </span>
                              Note
                            </h4>
                            <p>The screen pops back with a red message at the bottom of the screen if your payment was not successfull. Read the message it shows to know why the payment wasn’t successful.</p>
                            <p>Hence, if your payment was successful, a green snackbar displays at the bottom of the screen. You can view the post you bought</p>
                          </div>
                        </div>
                        <br />
                        <h5>Steps To Pay With Bank Card:</h5>
                        <p>Vigoplace made it also possible for you to make your payment to buy a content using an eligible Bank Card, but before choosing to pay with a Bank Card some factors are required such as:</p>
                        <ul>
                          <li>The Bank Card in use should be an active one</li>
                          <li>The Bank account should have the particular currency in which the other user requires to Buy the content.</li>
                          <li>Your Bank account balance should have the amount in which you want to Buy the content with</li>
                        </ul>
                        <br />
                        <h5>If all the above requirements are correct, below are steps to Buy a content using your Bank Card:</h5>
                        <ol>
                          <li>Choose the “Bank Card” and click on the button at the bottom of the screen to navigate to the payment screen.</li>
                          <div className="slideContainer2 simplelightbox-gallery">
                            <div className="simplelightbox-gallery row">
                              <div className="col-12 mb-3">
                               <img className="figure-img img-fluid shadow rounded" src={Buypost7} alt="" title="CoderPro Home Page" />
                              </div>
                            </div>
                          </div>
                          <li>At the bottom of the payment screen, there are two options “No, cancel” and “Yes”. Click on “Yes” to subscribe to the post, you can click on “No, cancel” to cancel the subscription and go back.</li>
                          <div className="slideContainer2 simplelightbox-gallery">
                            <div className="simplelightbox-gallery row">
                              <div className="col-12 mb-3">
                               <img className="figure-img img-fluid shadow rounded" src={Buypost8} alt="" title="CoderPro Home Page" />
                              </div>
                            </div>
                          </div>
                          <li>If your option is yes, a card pops out with the requirement to fill in your bank details, click on the pay to complete your payment</li>
                          <div className="slideContainer2 simplelightbox-gallery">
                            <div className="simplelightbox-gallery row">
                              <div className="col-12 mb-3">
                               <img className="figure-img img-fluid shadow rounded" src={Buypost9} alt="" title="CoderPro Home Page" />
                              </div>
                            </div>
                          </div>
                          <div className="callout-block callout-block-info">
                            <div className="content">
                              <h4 className="callout-title">
                                <span className="callout-icon-holder me-1">
                                  <i className="fas fa-info-circle"></i>
                                </span>
                                Note
                              </h4>
                              <p>The screen pops back with a red message at the bottom of the screen if your payment was not successful. Read the message it shows to know why the payment wasn’t successful.</p>
                              <p>Hence, if your payment was successful, a green snackbar displays at the bottom of the screen. You can view the post you bought</p>
                            </div>
                          </div>


                        </ol>



                      </ol>
                    </li>
                  </ol>

                </section>


                <section className="docs-section" id="subscribe">
                  <h3>Subscribe</h3>
                  <p>To get total access to another users place, Vigoplace made it possible to subscribe to a user’s Channel place to as to get access to every paid post the user makes. To know how to subscribe, follow the below instruction:</p>
                  <ol>
                    <li>Go to the post you want to subscribe to</li>
                    <li>Click on the rectangular bar at the middle of the post card, a bottom sheet pops out</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Sub1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the “Subscribe” text to go to the next page</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Sub2} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on your subscription card to navigate to the method of payment</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Sub3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Choose a method to pay with either by Bank Card or Wallet</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={Sub4} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                  </ol>

                </section>

                <section className="docs-section" id="basic">
                  <h3>BASIC POSTS ON A CHANNEL PLACE</h3>
                  <p>A basic post of a channel place are normal posts but are done in a channel place account. These posts can only be viewed on a channel place account that the post was made from, the purpose of this is because when a user creates a channel place, the user has automatically entered another phase in Vigoplace which allows the user also make basic post which are not paid for on that channel account.</p>
                  <h5>HOW TO CREATE A BASIC POST ON A CHANNEL PLACE</h5>
                  <p>Creating of basic posts on channel place is as creating a basic post on any other place on Vigoplace. To create a basic post on a channel place the user doesn’t need any requirement except the fact that the user must have a channel place account first. Before making a basic post on a channel place the user must be on the channel account in which the user wants to make the post from, the following steps below are ways to create a basic post on a channel place:</p>
                  <ol>
                    <li>Click on your profile icon to make sure you are on the channel place account you want to make the post on</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={basic1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the + sign on the bottom navigation bar to go the page where you can make the post.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={basic2} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={basic3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Choose the “Basic post” option.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={basic4} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={basic5} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Create the type of post by choosing any of the option at the bottom of the screen “Open camera, Add photo, Add video</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={basic6} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Write in a description of what you want your post to take on the text field at the top part of the screen</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={basic7} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>After choosing an option, you can click on the “Setting” to add modification to your post</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={basic8} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={basic9} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the check icon at the top right corner of the screen to save and complete your post.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                         <img className="figure-img img-fluid shadow rounded" src={basic10} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>

                    <div className="callout-block callout-block-info">
                      <div className="content">
                        <h4 className="callout-title">
                          <span className="callout-icon-holder me-1">
                            <i className="fas fa-info-circle"></i>
                          </span>
                          Note
                        </h4>
                        <p>A red message at the bottom of the screen displays when your post isn’t successful. Read the message it shows to know why your post wasn’t successful.</p>
                        <p>Hence, if your post was successful, a green snackbar displays at the bottom of the screen. And you can see your post immediately the screen pops.</p>
                      </div>
                    </div>
                    <br />
                    <h5>VIEWING YOUR BASIC POST</h5>
                    <p>Viewing your basic post on a channel place is as simple as viewing a regular, there are two ways a user can view their own basic post on their channel place which are:</p>
                    <ol>
                      <li><b>BY HOME FEED VIEW:</b> A user can come across their post they made on a channel place when they are scrolling through the feeds in Vigoplace. This is the only way a user can view any post from any place on vgoplace</li>
                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                           <img className="figure-img img-fluid shadow rounded" src={basic11} alt="" title="CoderPro Home Page" />
                          </div>
                        </div>
                      </div>
                      <li><b>THROUGH PROFILE:</b> To view your basic post of a channel place on profile the user must have to go to the particular channel account that post was made from, on the profile they are three (3) tabs, clicking on the second tab(2) “Posts” would display all the basic post made on that channel place account. But in case where you are a visitor to another persons channel place, there are only two(2) tabs “Pay-To-View” and ”Posts”, click on the post tab to view the person’sbasic post of that channel place.  Vigoplace made it possible that for every place created, the user can make basic posts for only that place which means the posts can’t be seen in other place profiles</li>
                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                           <img className="figure-img img-fluid shadow rounded" src={basic12} alt="" title="CoderPro Home Page" />
                          </div>
                          <p><b>View as the user</b></p>

                        </div>
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                           <img className="figure-img img-fluid shadow rounded" src={basic13} alt="" title="CoderPro Home Page" />
                          </div>
                          <p><b>View as the visitor</b></p>
                        </div>
                      </div>
                    </ol>
                  </ol>
                </section>

                <section className="docs-section" id="subscribers">
                  <h3>SUBSCRIBERS</h3>
                  <p>The third tab on a channel place is the subscriber tab, this is where all the list of every user that has purchased a content you’ve ever posted on channel place are found. Vigoplace made this tab so as to show you as a user all the users that have purchased your content even if they are not part of your followers, and this tab shows you the purchase option, date of purchase and also date the purchase the person made would expire. It also shows other purchase the user has made on Vigoplace. This tab only shows other user purchases on your place without showing your purchase on other user’s place.</p>
                  <br />
                  <h5>WHERE TO SEE THE LIST OF YOUR SUBSCRIBERS</h5>
                  <p>For a user to see the list of subscribers or list of users that has purchased any of their content, the user would have to go into the particular channel account he or she wants. The list of subscribers can be seen on the profile of that particular channel place by clicking on the third (3) tab of the channel profile.</p>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                       <img className="figure-img img-fluid shadow rounded" src={subscribers1} alt="" title="CoderPro Home Page" />
                      </div>
                    </div>
                  </div>
                  <p>Vigoplace has made it possible for you to view the history of your subscribers when you click on the user’s name. You can also view the details of the subscription the user as made.</p>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img className="figure-img img-fluid shadow rounded" src={subscribers2} alt="" title="CoderPro Home Page" />
                      </div>
                    </div>
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img className="figure-img img-fluid shadow rounded" src={subscribers3} alt="" title="CoderPro Home Page" />
                      </div>
                    </div>
                  </div>


                </section>




                <section className="docs-section" id="sub">
                  <h3>SUBSCRIPTION </h3>
                  <p>Subscription are recurring payments (any currency) a user initiates in exchange for access to any content, premium features, or services. In Vigoplace, a subscription is a monetary setting that determines which content a user has access to on Channel Place. These settings can be applied to a Pay-To-View post when creating it, if a user subscribes to this post that your get full access to the content creator’s Pay-To-View post.</p>
                  <p>You can create a subscription, edit an already made subscription and also delete a subscription</p>

                  <h5>CREATING SUBSCRIPTION</h5>

                  <p>One of the most awesome feature about Vigoplace is being able to create another new subscription even if you have a subscription already. In a case where your previous subscription does
                    not support a content, you can make another bigger subscription to get access to the content without having to delete or edit the previous subscription.
                  </p>

                  <p>Some factors affect a user from creating or editing a subscription such as: </p>
                  <ul>
                    <li>A user must have an account</li>
                    <li>A user must have an active wallet before he or she can create a subscription.</li>
                  </ul>
                  <p>If a user has the above requirement checked, the user can create a subscription following the below steps:</p>
                  <ol>
                    <li>Click on your profile</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={subscription} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the top right vertical dots, a scrollable bottom sheet would pop out carrying different options</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={csub2} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on settings to go to the next page</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={csub3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on subscriptions.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={csub4} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>If you don’t have any subscription and want to learn more about subscription click on the purple text at the middle of the screen</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={csub5} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>If you don’t have any subscription or want to add another subscription click on the + at the top right of screen.          </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={csub6} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Fill in the required text field:
                      <ul>
                        <li>Subscription name: The name you want your subscription to be</li>
                        <li>Billing duration: Click on the filed to select how long you want your subscription to be (Monthly, Quarterly, Yearly</li>
                        <li>	Description: Write in the description of your subscription. Your purpose of subscribing</li>
                        <li>Pricing: Putting in the amount you want to subscribe. In this case, to select an amount you'll have to click on the "Add another currency" purple text, then select a currency. Put in the amount you want to subscribe with.</li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img className="figure-img img-fluid shadow rounded" src={csub7} alt="" title="CoderPro Home Page" />
                            </div>
                          </div>
                        </div>
                        <div className="callout-block callout-block-info">
                          <div className="content">
                            <h4 className="callout-title">
                              <span className="callout-icon-holder me-1">
                                <i className="fas fa-info-circle"></i>
                              </span>
                              Note
                            </h4>
                            <p>You can only select a currency that is already saved in your wallet</p>
                          </div>
                        </div>
                        <li>When you are done click on "Create subscription", at the bottom of the screen to create and save your subscription.</li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img className="figure-img img-fluid shadow rounded" src={csub8} alt="" title="CoderPro Home Page" />
                            </div>
                          </div>
                        </div>
                      </ul>
                    </li>
                    <div className="callout-block callout-block-info">
                      <div className="content">
                        <h4 className="callout-title">
                          <span className="callout-icon-holder me-1">
                            <i className="fas fa-info-circle"></i>
                          </span>
                          Note
                        </h4>
                        <p>A user can also create more than one subscription following these same process.</p>
                      </div>
                    </div>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={csub9} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                  </ol>

                  <h3>EDIT A SUBSCRIPTION </h3>
                  <p>Editing of subscription is also possible, you can edit an already made subscription.</p>
                  <h5>These are the follow steps to edit a subscription:</h5>
                  <p>If the user is not on the subscription screen already, this are the steps you can follow to navigate to the screen:</p>
                  <ol>
                    <li>Click your profile</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={editcsub1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the top right vertical dots, a scrollable bottom sheet would pop out carrying different options.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={editcsub2} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on settings to go into the next page.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={editcsub3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on subscriptions.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={editcsub4} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <h5>Then the user can edit the subscription with the following steps:</h5>
                    <li>Click on the vertical dots at the top right of your subscription card.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={editcsub5} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>A bottom sheet would display carrying the option "Edit", click on the "Edit" to navigate to the edit screen.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={editcsub6} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Edit your subscription detail</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={editcsub7} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on "Edit subscription", at the bottom of the screen to save your changes</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={editcsub8} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <div className="callout-block callout-block-info">
                      <div className="content">
                        <h4 className="callout-title">
                          <span className="callout-icon-holder me-1">
                            <i className="fas fa-info-circle"></i>
                          </span>
                          Note
                        </h4>
                        <p>The screen pops back with a red message at the bottom of the screen if your subscription edit was not successfully. Read the message it shows to know why your subscription wasn’t successful</p>
                        <p>if your edit was successful, a green snackbar displays at the bottom of the screen. And you can see your subscription details at the top of the screen that pops</p>
                      </div>
                    </div>
                  </ol>

                  <h3>DELETE A SUBSCRIPTION</h3>
                  <p>Delete of subscription is also possible, the user can only delete a subscription after creating or editing one.</p>
                  <h5>HOW TO DELETE SUBSCRIPTION</h5>
                  <p>If the user is not on the subscription screen already, this are the steps you can follow to the screen:</p>
                  <ol>
                    <li>Click your profile.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={deletecsub1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the top right vertical dots, a scrollable bottom sheet would pop out carrying different options.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={deletecsub2} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on settings to go into the next page</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={deletecsub3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li> Click on subscriptions</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={deletecsub4} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <h5>Then the user can edit the subscription with the following steps:</h5>
                    <li>Click on the vertical dots on the top right of your subscription card</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={deletecsub5} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>A bottom sheet would display carrying the option "Delete", click on the "Delete" to delete your subscription. </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={deletecsub6} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                  </ol>
                  <br />

                  <h3>HOW TO USE A SUBSCRIPTION</h3>
                  <p>A user can use their created subscription to watch or read any pay to view content on other users Channel posts by buying, renting or subscribing to a place.</p>
                  <p>Vigoplace has a feature that enable you to watch a trailer of a video before deciding either to purchase the video or not. </p>
                </section>

                <section className="docs-section" id="rent">
                  <h3>Rent</h3>
                  <p>
                    A user can rent a content for a particular period of time, for a content to be rented the content must have a rent option To get rental access to another user’s particular content, follow the below instruction</p>
                  <ol>
                    <li>Go to the post you want to rent.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={rent1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the rectangular bar at the middle of the post card, a bottom sheet pops out.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={rent2} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the “Rent” text to go to the next page.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={rent3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the list to navigate to the method of payment</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={rent4} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Choose a method to pay with either by Bank Card or Wallet  </li>

                  </ol>

                </section>

                <section className="docs-section" id="delete">
                  <h3>DELETING A CHANNEL PLACE</h3>
                  <p>In Vigoplace it is possible to create more than a single channel place account and in a case where a content creator does not want to make use of the place again, the creator can easily delete the place entirely. Deleting of a channel place deletes all the post made from that place along with it.</p>
                  <h5> STEPS TO DELETE A CHANNEL PLACE:</h5>
                  <ol>
                    <li>To delete a channel place account switch to the targeted, click on the vertical dot at top right corner of the page to display a bottom sheet.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={delete1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={delete2} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on “Delete Place” option on the sheet to continue the process</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={delete3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>Click on the “Cancel” if you want to cancel the process to delete the channel account, click on “Delete” to permanently delete the place</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={delete4} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>After the place is successfully deleted, your profile reverts automatically to your main profile</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={delete5} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                  </ol>

                </section>










              </article>
              {/* <!--//docs-article--> */}








            </div>
          </div>
        </div>
        {/* <!--//docs-wrapper--> */}
      </div>
    </>
  );
}

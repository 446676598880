import React from "react";
import newspost1 from "../assets/images/newspost1.png";
import supportpost1 from "../assets/images/supportpost1.png";
import supportpost2 from "../assets/images/supportpost2.png";
import supportpost3 from "../assets/images/supportpost3.png";
import supportpost4 from "../assets/images/supportpost4.png";
import supportpost5 from "../assets/images/supportpost5.png";
import supportpost6 from "../assets/images/supportpost6.png";
import supportpost7 from "../assets/images/supportpost7.png";
import supportpost8 from "../assets/images/supportpost8.png";
import fundpost9 from "../assets/images/fundpost9.png";

export default function SupportandDonatePost() {
  return (
    <>
      <div className="docs-page">
        <div className="docs-wrapper">
          {/* <Sidebar /> */}

          {/* <!--//docs-sidebar--> */}

          <div className="docs-content">
            <div className="container">
              <article className="docs-article" id="newspost">
                <section className="docs-section" id="newspost">
                  <h1 className="docs-heading">SUPPORT AND DONATE POST</h1>
                  <p>
                    Vigoplace allows users to create support and donate posts,
                    enabling them to share their projects or causes with the
                    community. Other users can then support or donate to these
                    projects, with donations accepted in both USD and NGN
                    (Naira). This documentation provides a comprehensive guide
                    on how to create support and donate posts, and how users can
                    support or donate to projects.
                  </p>
                  <h5>Creating a Support or Donate Post</h5>
                  <p>
                    Support posts are designed to gather encouragement,
                    assistance, or resources from other users. Here's how users
                    can create a support post:{" "}
                  </p>

                  <ol>
                    <li>
                      Navigate to the "Add post" section within the app which is
                      at the bottom of your screen.
                    </li>

                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={newspost1}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={supportpost1}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      Click on the "Support Post" button to start a new post.
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={supportpost2}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      Filling Out the Support Post Form
                      <p>
                        - Description: Provide a detailed explanation of your
                        project or cause. Explain why you need support and how
                        others can help
                      </p>
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={supportpost3}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <p>
                      - Categories: Select relevant categories for your post to
                      help others find it easily.
                    </p>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={supportpost4}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <p>
                      - Currency: Select your preferred currency you want your
                      supports to come in with.
                    </p>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={supportpost4}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <p>
                      - Attachments: You can attach images, videos, to give more
                      context to your project.
                    </p>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={supportpost5}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      Select the type of post you want to make if it's a
                      “Support post” or “Donate post”
                    </li>
                    <li>
                      After filling out the form, click the check button at the
                      top right corner of the screen to submit your support
                      post.
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={supportpost5}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      Your post will now be visible to other users, who can
                      offer their support or donate.
                    </li>
                  </ol>
                  <br />
                  <br />

                  <h5 id="supporting">Supporting or Donating on a Post</h5>
                  <p>
                    Users can support or donate post by going through the
                    owner's profile, searching for the post using our search
                    engine or through the feed:
                  </p>

                  <ol>
                    <li>Navigate to the post that interest you.</li>
                    <li>
                      Click on a “Donate” or “Support” at the right side of the
                      post to pop a drop sheet to fill.
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={supportpost6}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <p>
                      - Choose if you want to an anonymous donation/support or
                      not by toggling the button
                    </p>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={supportpost7}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <p>
                      - Input the desired amount you want to donate/support the
                      user with.
                    </p>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={supportpost8}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <p>
                      - You can pay with your virtual bank debit card or through
                      your wallet.
                    </p>
                    <li>
                      Click on the “Make Payment” button at the bottom of the
                      sheet to complete the payment
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={supportpost8}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      After donating or supporting a post, you will receive a
                      confirmation message at the bottom of your screen
                      indicating that your payment was successful.
                    </li>
                    <p>
                      NOTE: All donations and supports are going into your
                      Vigoplace wallet.
                    </p>
                  </ol>

                  <br />
                  <h5>Check your donors on your post</h5>
                  <p>
                    After a donation or support are made, you can see the list
                    of your donors by clicking on the left bottom button of the
                    post “Donors” to see your list of donors on the post.
                  </p>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={supportpost6}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <br />
                  <h5>Deleting your post</h5>
                  <p>
                    - You can also delete the post after it's been made if
                    you're not satisfy, or you’ve gotten the desired amount for
                    the reason of the post.
                  </p>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={fundpost9}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>
                  <p>
                    This documentation provides an overview of how users can
                    create support and donate posts, and how other users can
                    offer support and make donations. For further assistance or
                    technical support, please contact our customer support team.
                  </p>
                </section>

                {/* NB. All these settings can be changed even after you have created the gift post */}
              </article>
              {/* <!--//docs-article--> */}
            </div>
          </div>
        </div>
        {/* <!--//docs-wrapper--> */}
      </div>
    </>
  );
}

import React from "react";
import newspost1 from "../assets/images/newspost1.png";
import CreateMarketPlacePost from "../assets/images/createmarketplacepost.jpg";
import newspost3 from "../assets/images/newspost3.png";
import newspost4 from "../assets/images/newspost4.png";
import newspost5 from "../assets/images/newspost5.png";
import newspost6 from "../assets/images/newspost6.png";
import newspost7 from "../assets/images/newspost7.png";
import newspost8 from "../assets/images/newspost8.png";
import newspost9 from "../assets/images/newspost9.png";

export default function NewsPost() {
  return (
    <>
      <div className="docs-page">
        <div className="docs-wrapper">
          {/* <Sidebar /> */}

          {/* <!--//docs-sidebar--> */}

          <div className="docs-content">
            <div className="container">
              <article className="docs-article" id="newspost">
                <section className="docs-section" id="newspost">
                  <h1 className="docs-heading">NEWS POSTS</h1>
                  <p>
                    The news post feature on Vigoplace allows users to create
                    and share news-related content. This could include
                    announcements, reports, updates, or stories on various
                    topics such as current events, technology, entertainment,
                    sports, and more. The news post feature is designed to keep
                    users informed and engaged with the latest news and trends.
                    This documentation provides a comprehensive guide on how to
                    create, manage, and interact with news posts.
                  </p>
                  <h5>Creating a News Post</h5>
                  <p>To create a news post, follow these steps: </p>

                  <ol>
                    <li>
                      Navigate to the "Add post" section within the app which is
                      at the bottom of your screen.
                    </li>

                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={newspost1}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      Click on the "News Post" button to start a new post.
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={CreateMarketPlacePost}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      Filling Out the News Post Form: Write the main body of
                      your news post. This is where you include the news story
                      or announcement.{" "}
                      <p>
                        Categories: Select the appropriate category for your
                        news post to help users find it easily (e.g.,
                        Technology, Entertainment, Sports, etc.).
                      </p>
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={newspost3}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <p>
                      - Link (Optional): Add relevant links to help users read
                      more about the news
                    </p>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={newspost4}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <p>
                      - Attachments: You can attach images, videos, to give more
                      context to your project.
                    </p>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={newspost5}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      After filling out the form, click the check button at the
                      top right corner of the screen to submit your news post.
                    </li>
                    <li>Your post will now be visible to other users.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={newspost6}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                  </ol>
                  <br />
                  <br />

                  <h5 id="managingPost">Managing a News Post</h5>
                  <p>
                    Once you've created a news post, you can manage it in
                    several ways:
                  </p>

                  <ol>
                    <li>Editing a News Post</li>
                    <p>- Navigate to the news post.</p>
                    <p>
                      - Click at the top vertical option button at the top right
                      of the post.
                    </p>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={newspost7}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <p>
                      - Click on the "Edit" button to make changes to the
                      description, categories, or link.
                    </p>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={newspost8}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <p>
                      - After editing, click the check button at the top right
                      corner of the screen to update your news post.
                    </p>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={newspost9}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>Deleting a News Post</li>
                    <p> - Navigate to the news post.</p>
                    <p>
                      - Click at the top vertical option button at the top right
                      of the post.
                    </p>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={newspost7}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <p>- Click on the "delete" button to delete it.</p>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={newspost8}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                  </ol>

                  <br />
                  <h5>Interacting with News Posts</h5>
                  <p>
                    Users can interact with news posts in the following ways:
                  </p>
                  <ol>
                    <li>Liking a News Post</li>
                    <p>
                      - Click on the "Like" button to show appreciation for a
                      news post.
                    </p>
                    <p>
                      - The number of likes is displayed to indicate popularity.
                    </p>
                    <li>Commenting on a News Post</li>
                    <p>
                      - Scroll to the comment section at the bottom of the news
                      post.
                    </p>
                    <p>- Write your comment and click "Post" to submit it.</p>
                    <li>Sharing a News Post</li>
                    <p>
                      - Click the "Share" button to share the news post with
                      others.
                    </p>
                    <p>
                      - You can share via social media, messaging apps, or
                      email.
                    </p>
                  </ol>
                  <br />
                  <h5>Conclusion</h5>
                  <p>
                    The news post feature is designed to keep users engaged and
                    informed with the latest news and trends. By following this
                    guide, you can create compelling news posts, manage them,
                    and interact with other users' news content. If you have
                    questions, technical issues, or need assistance with the
                    news post feature, please contact our support team.
                  </p>
                </section>

                {/* NB. All these settings can be changed even after you have created the gift post */}
              </article>
              {/* <!--//docs-article--> */}
            </div>
          </div>
        </div>
        {/* <!--//docs-wrapper--> */}
      </div>
    </>
  );
}

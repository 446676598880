import React from "react";
import verification1 from "../assets/images/verification1.png";
import verification2 from "../assets/images/verification2.png";
import verification3 from "../assets/images/verification3.png";
import verification4 from "../assets/images/verification4.png";
import verification5 from "../assets/images/verification5.png";
import verification6 from "../assets/images/verification6.png";

export default function Verifications() {


  return (
    <>
      <div className="docs-page">
        <div className="docs-wrapper">
          <div className="docs-content">
            <div className="container">

              <article className="docs-article" id="verifications">
                <section className="docs-section" id="verifications">
                  <h1 className="docs-heading">Verifications</h1>
                  <p>For any user to get complete access and easy usage to every feature on vigoplace, the user must add and verify their phone number also the email address used to open the Vigoplace account. A user must have a verified email and phone number to be able to keep using vigoplace</p>
                 
                  <h5>Below are few steps used to verify the above requirement:</h5>
                  <ol>
                    <li>Go to your profile, click on the vertical three dot at the top of the profile page so a bottom sheet would appear</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={verification1} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>
                    <li>On the bottom sheet scroll to a list called verification, click on it to take you to the page where you can fill in your phone number or email address and tap the send button to   request for a verification code which would be sent to the various email address and phone number you’ve provided</li>

                    <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={verification2} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={verification3} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>

                    <li>An OTP code would be sent to you, go to your email address or phone number to copy the code sent, then paste it in the space given for the code to verify them</li>

                    <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={verification4} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={verification5} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>

                    <li>After the Code has been imputed in the textfield for OTP click on Verify Email/ Phone number to complete your verification.</li>

                    <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img className="figure-img img-fluid shadow rounded" src={verification6} alt="" title="CoderPro Home Page" />
                        </div>
                      </div>
                    </div>

                  </ol>
                </section>
              </article>
            </div>
          </div>
        </div>
        {/* <!--//docs-wrapper--> */}
      </div>
    </>
  );
}

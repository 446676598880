import React from "react";
import newspost1 from "../assets/images/newspost1.png";
import supportpost1 from "../assets/images/supportpost1.png";
import fundpost1 from "../assets/images/fundpost1.png";
import fundpost2 from "../assets/images/fundpost2.png";
import fundpost3 from "../assets/images/fundpost3.png";
import fundpost4 from "../assets/images/fundpost4.png";
import fundpost5 from "../assets/images/fundpost5.png";
import fundpost6 from "../assets/images/fundpost6.png";
import fundpost7 from "../assets/images/fundpost7.png";
import fundpost8 from "../assets/images/fundpost8.png";
import supportpost7 from "../assets/images/supportpost7.png";
import supportpost8 from "../assets/images/supportpost8.png";
import fundpost9 from "../assets/images/fundpost9.png";

export default function FundraisingPost() {
  return (
    <>
      <div className="docs-page">
        <div className="docs-wrapper">
          {/* <Sidebar /> */}

          {/* <!--//docs-sidebar--> */}

          <div className="docs-content">
            <div className="container">
              <article className="docs-article" id="newspost">
                <section className="docs-section" id="newspost">
                  <h1 className="docs-heading">FUNDRAISING POST</h1>
                  <p>
                    The fundraising feature on Vigoplace allows users to create
                    posts to raise funds for projects, events, personal needs,
                    charitable causes, or any other purpose requiring financial
                    support. This documentation provides a comprehensive guide
                    on how to create a fundraising post and how users can
                    contribute funds.
                  </p>
                  <h5>Creating a Fundraising Post</h5>
                  <p>To create a fundraising post, follow these steps:</p>

                  <ol>
                    <li>
                      Navigate to the "Add post" section within the app which is
                      at the bottom of your screen.
                    </li>

                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={newspost1}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={supportpost1}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      Click on the "Fundraising Post" button to start a new
                      post.
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={supportpost1}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={fundpost1}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>Filling Out the Support Post Form</li>
                    <p>
                      - Title: Enter a clear and concise title for your
                      fundraising post.
                    </p>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={fundpost2}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>

                    <p>
                      - Description: Provide a detailed explanation of your
                      project or cause. Explain why you need support and how
                      others can help.
                    </p>

                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={fundpost3}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <p>
                      - Enter target amount: Set a financial target for your
                      fundraising campaign.
                    </p>
                    <p>
                      - Currency: Select your preferred currency you want your
                      supports to come in with.
                    </p>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={fundpost4}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <p>
                      - Select target date: Specify the duration of the
                      fundraising campaign. The date starts the day the post was
                      created and ends the day that is been set.
                    </p>
                    <p>
                      - Categories: Select relevant categories for your post to
                      help others find it easily.
                    </p>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={fundpost5}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <p>
                      - Attachments: You can attach images, videos, to give more
                      context to your project.
                    </p>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={fundpost6}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      After filling out the form, click the check button at the
                      top right corner of the screen to submit your fundraising
                      post.
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={fundpost6}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      Your post will now be visible to other users, who can
                      offer their support or donate.
                    </li>
                  </ol>
                  <br />
                  <br />

                  <h5 id="promoting">Promoting Your Fundraising Post</h5>
                  <p>
                    Once your fundraising post is live, you can promote it to
                    reach a wider audience:
                  </p>

                  <ol>
                    <li>Sharing on Social Media .</li>
                    <p>
                      - Share your fundraising post on other social media
                      platforms to reach a broader audience.
                    </p>
                    <p>
                      - Use appropriate hashtags and tag relevant users to
                      increase visibility
                    </p>
                    <li>Engaging with the Community</li>
                    <p>
                      - Engage with users who comment on your fundraising post.
                    </p>
                    <p>
                      - Answer questions, provide additional information, and
                      thank donors to build a positive relationship with
                      supporters.
                    </p>
                  </ol>
                  <br />

                  <h5>Donating to a Fundraising Post</h5>
                  <p>
                    Users can support/donate to your fundraising post by going
                    through the owner's profile, searching for the post using
                    our search engine or through the feed:
                  </p>
                  <ol>
                    <li>
                      Navigate to the post that interest you or the fundraising
                      post.
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={fundpost7}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      Click on a “Donate” at the right side of the post to pop a
                      drop sheet to fill.
                    </li>
                    <p>
                      - Choose if you want to an anonymous donation or not by
                      toggling the button
                    </p>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={supportpost7}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <p>- Input the desired amount you want to donate with.</p>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={supportpost8}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <p>
                      - You can pay with your virtual bank debit card or through
                      your wallet.
                    </p>
                    <li>
                      Click on the “Make Payment” button at the bottom of the
                      sheet to complete the payment
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={supportpost8}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      After donating on a post, you will receive a confirmation
                      message at the bottom of your screen indicating that your
                      payment was successful.
                    </li>
                    <p>
                      NOTE: All donations and supports are going into your
                      Vigoplace wallet.
                    </p>
                  </ol>

                  <br />
                  <h5>Check your donors on your post</h5>
                  <p>
                    - After donations are made, you can see the list of your
                    donors by clicking on the left bottom button of the post
                    “Donors” to see your list of donors on the post.
                  </p>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={fundpost8}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>

                  <br />
                  <h5>Deleting your post</h5>
                  <p>
                    - You can also delete the post after it's been made if
                    you're not satisfy, or you’ve gotten the desired amount for
                    the reason of the post.
                  </p>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={fundpost9}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <h5>Adjusting the Fundraising Goal</h5>
                  <p>
                    - If needed, you can adjust the fundraising goal based on
                    the campaign's progress.
                  </p>

                  <p>
                    - Click "Edit" on the fundraising post to update the goal or
                    other details. technical support, please contact our
                    customer support team.
                  </p>
                  <div className="slideContainer2 simplelightbox-gallery">
                    <div className="simplelightbox-gallery row">
                      <div className="col-12 mb-3">
                        <img
                          className="figure-img img-fluid shadow rounded"
                          src={fundpost9}
                          alt=""
                          title="CoderPro Home Page"
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <h5>Conclusion</h5>
                  <p>
                    The fundraising feature allows users to raise funds for
                    various causes and projects. By following this guide, you
                    can create a compelling fundraising post, promote it
                    effectively, and engage with supporters. For additional
                    assistance, technical issues, or questions about the
                    fundraising process, please contact our support team
                  </p>
                </section>

                {/* NB. All these settings can be changed even after you have created the gift post */}
              </article>
              {/* <!--//docs-article--> */}
            </div>
          </div>
        </div>
        {/* <!--//docs-wrapper--> */}
      </div>
    </>
  );
}

import React from 'react'
import { Link} from "react-router-dom";


function SidebarLinks({to, setShowsidebar, text, children }) {
  return (
     <Link 
     className="nav-link" 
     to={to} 
     onClick={() => setShowsidebar((sidebar)=>!sidebar)}
     >
      {children}
      {text}
      </Link>
  )
}

export default SidebarLinks

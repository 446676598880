import React from "react";
import earnings1 from "../assets/images/earnings1.jpeg";
import earnings2 from "../assets/images/earnings2.jpeg";
import earnings4 from "../assets/images/earnings4.jpeg";
import earnings5 from "../assets/images/earnings5.jpeg";
import earnings6 from "../assets/images/earnings6.jpeg";
import earnings7 from "../assets/images/earnings7.jpeg";
import earnings8 from "../assets/images/earnings8.jpeg";
import earnings9 from "../assets/images/earnings9.jpeg";
import earnings10 from "../assets/images/earnings10.jpeg";

export default function Earnings() {
  return (
    <>
      <div className="docs-page">
        <div className="docs-wrapper">
          <div className="docs-content">
            <div className="container">
              <article className="docs-article" id="earnings">
                <section className="docs-section" id="earnings">
                  <h1 className="docs-heading">Earnings</h1>

                  <h5>Introduction </h5>
                  <p>
                    Vigoplace is committed to providing its users with a
                    rewarding experience through monetary incentives for their
                    activities on the app. This document outlines the policy
                    regarding earnings, withdrawal procedures, and currency
                    options available to our users.
                  </p>
                  <br />
                  <h5>Earning Activities:</h5>
                  <p>
                    Users can earn money on Vigoplace by engaging in various
                    activities such as:
                  </p>
                  <ol>
                    <li>Number of Views on your post </li>
                    <li>Comments on your posts</li>
                    <li>Followers on your profile</li>
                    <li>Number of your Video Posts</li>
                  </ol>
                  <h5>Earning Structure</h5>
                  <p>
                    The amount earned for each activity may vary and will be
                    clearly indicated within the app. Vigoplace reserves the
                    right to adjust earning rates based on factors such as user
                    engagement and advertiser demand.
                  </p>
                  <p>
                    Additionally, each user has a monthly earning limit,
                    determined by their level and type of activities and
                    engagements on their profile. This means there is a cap on
                    how much you can earn based on the type of account they have
                    with us within a single month. Once you reach this limit,
                    you won't be able to earn again until the following month.
                    This structure helps maintain fairness and sustainability.
                  </p>
                  <br />
                  <h5>Withdrawal Policy</h5>
                  <p>
                    Earnings on Vigoplace are exclusively processed monthly.
                    Users have the flexibility to accumulate any amount of
                    earnings before becoming eligible to request for their
                    earning. We offer you free access to withdraw any amount
                    they made, there is no specific threshold amount required
                    for withdrawal, fostering a user-friendly experience.
                  </p>
                  <br />
                  <p>
                    All earning requests are processed within 3-5 business days.
                    Users must follow the appropriate steps to request for
                    earning:
                  </p>
                  <p>
                    To check the amount you have made from your activities,
                    follow the steps below:
                  </p>

                  <ul>
                    <li>
                      Click on the profile icon at the bottom right corner of
                      your screen
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={earnings1}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={earnings2}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      Click on the wallet icon at the top right corner of your
                      screen which takes you to another page where you input
                      your wallet passcode
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={earnings2}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={earnings4}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      After inputting your pin, the next screen displays where
                      you have three cards labelled ‘Vigoplace Earnings’ NGN
                      wallet’ and USD wallet’ select the ‘Vigoplace earnings
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={earnings5}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      It takes you to your earnings page where you can view the
                      earnings for the present month, you’re in, and also view
                      the history of earning transaction.
                    </li>

                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={earnings6}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={earnings7}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      {" "}
                      To request a payout a “Request a payout” button is located
                      at the bottom of the screen. Before doing so you can also
                      choose the month and the currency type you want the money.
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={earnings8}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={earnings9}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      After a successful withdrawal you can see the amount
                      reflect on your selected wallet currency{" "}
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={earnings10}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                  </ul>

                  <h5>Currency Options</h5>
                  <p>
                    Vigoplace supports withdrawals in multiple currencies to
                    cater to the diverse global user base. Users can choose
                    their preferred currency wallet from the options available
                    within the app. And this money can then be transferred to
                    your virtual account from your wallet.
                  </p>
                  <h5>Conclusion</h5>
                  <p>
                    We value the participation and engagement of its users and
                    strives to provide a fair and transparent earnings system.
                    By adhering to this earnings policy, we aim to create a
                    rewarding experience for our users while maintaining the
                    integrity and sustainability of our platform.
                  </p>
                  <p>
                    Contact Support (If needed): In case of any discrepancies or
                    questions regarding earnings, users can reach out to our
                    dedicated customer support team for assistance.
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
        {/* <!--//docs-wrapper--> */}
      </div>
    </>
  );
}
